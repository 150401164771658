import { FixedPoolModel } from '@/models/fixed-pool-model'
import { MyAnchorWallet } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { ProgramError, Provider } from '@project-serum/anchor'
import { ENV as SOL_CHAINID } from '@solana/spl-token-registry'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { clusterApiUrl, ConfirmOptions, Connection, SendTransactionError } from '@solana/web3.js'
import { isNumber, last } from 'lodash-es'
import Web3 from 'web3'
import { IIdoContract } from './ido-contract-interface'
import { SolanaIdoContract } from './ido-contract-solana'
import { SolidityIdoContract } from './ido-contract-solidity'
import { MarketNftContract } from './market-nft-solidity'
import { SolanaWaggleForageContract } from './waggle-forage-solana'
import { WaggleForageContract } from './waggle-forage-solidity'

export interface WaggleForageOrder {
  id: any
  seller: string
  price: FixedNumber
  market: string
  buyer: string
  buyerBidPrice: FixedNumber
  buyerTotalPrice: FixedNumber
  createdAt: moment.Moment
}

const getChainConfig = (chainId: any) => {
  chainId = isNumber(chainId) ? +chainId : chainId
  let rpc = ''
  let name = ''
  let explorer = ''
  switch (chainId) {
    case 1:
    case 'eth':
      name = 'Ethereum Mainnet'
      rpc = 'https://speedy-nodes-nyc.moralis.io/d2e931da4619b9acf870755d/eth/mainnet'
      explorer = 'https://etherscan.io/'
      // rpc = 'https://cloudflare-eth.com'
      break
    case 3:
      name = 'Ropsten Test Network'
      rpc = 'https://eth-ropsten.alchemyapi.io/v2/4szhG-FVK337Gq63VnnPoB3VH2BLYIQE'
      explorer = 'https://ropsten.etherscan.io/'
      break
    case 56:
    case 'bsc':
      name = 'BSC MainNET'
      rpc = 'https://bsc-dataseed.binance.org'
      explorer = 'https://bscscan.com/'
      break
    case 97:
      name = 'BSC TestNET'
      rpc = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
      explorer = 'https://testnet.bscscan.com/'
      break
    case 103:
      name = 'Solana DevNET'
      rpc = 'https://api.devnet.solana.com'
      explorer = 'https://solscan.io/'
      break
    case 102:
      name = 'Solana TestNET'
      rpc = 'https://api.testnet.solana.com'
      explorer = 'https://solscan.io/'
      break
    case 101:
      name = 'Solana MainNET'
      rpc = getSolanaMainNetRpc()
      explorer = 'https://solscan.io/'
      break
  }
  return { rpc, name, explorer }
}
const getSolanaMainNetRpc = () => {
  return process.env.NODE_ENV === 'production'
    ? 'https://lively-snowy-violet.solana-mainnet.quiknode.pro/4154a567b5b82bd12c9bcadaaa29967e777fda73/'
    : 'https://solana-api.projectserum.com'
}
const getWeb3 = (chainId: any) => {
  chainId = isNumber(chainId) ? +chainId : chainId
  const { rpc } = getChainConfig(chainId)
  if (rpc) {
    const web3 = new Web3(new Web3.providers.HttpProvider(rpc))
    ;(web3 as any).chainId = chainId
    return web3
  } else return null
}
const getSolanaConfig = (chainId) => {
  const anchorWallet = new MyAnchorWallet(undefined)
  const opts: ConfirmOptions = {
    preflightCommitment: 'recent',
    commitment: 'recent',
  }
  let connection: Connection

  switch (+chainId) {
    case SOL_CHAINID.MainnetBeta:
      connection = new Connection(getSolanaMainNetRpc(), opts.preflightCommitment)
      break
    case SOL_CHAINID.Testnet:
      connection = new Connection(clusterApiUrl(WalletAdapterNetwork.Testnet), opts.preflightCommitment)
      break
    case SOL_CHAINID.Devnet:
    default:
      connection = new Connection(clusterApiUrl(WalletAdapterNetwork.Devnet), opts.preflightCommitment)
      break
  }
  return new Provider(connection, anchorWallet, opts)
}
const cachedContracts: { [id: string]: IIdoContract } = {}
function idoContractFactory(model: FixedPoolModel): IIdoContract | undefined {
  const { address: contractAddress, type, chain, chainId } = model
  let result: any = undefined
  if (contractAddress) {
    result = cachedContracts[contractAddress]
    if (!result) {
      switch (chain) {
        case 'sol':
          result = new SolanaIdoContract(contractAddress, getSolanaConfig(chainId))
          break
        case 'eth':
        case 'bsc':
        default: {
          const web3Result = getWeb3(chainId)
          if (!web3Result) throw 'Web3 is undefined'
          result = new SolidityIdoContract(contractAddress, web3Result, type)
          break
        }
      }
      cachedContracts[contractAddress] = result
    }
  }
  return result
}
function getSolidityIdoContract(contractAddress: string, web3: Web3) {
  contractAddress = web3.utils.toChecksumAddress(contractAddress)
  let contract = cachedContracts[contractAddress]
  if (!contract) {
    contract = new SolidityIdoContract(contractAddress, web3)
    cachedContracts[contractAddress] = contract
  }
  return contract
}
function getSolanaIdoContract(poolAddress: string, provider: Provider) {
  let contract = cachedContracts[poolAddress]
  if (!contract) {
    contract = new SolanaIdoContract(poolAddress, provider)
    cachedContracts[poolAddress] = contract
  }
  return contract
}
function getMarketNftContract(chainId: any) {
  chainId = +chainId
  switch (chainId) {
    case 56:
    case '56':
      return MarketNftContract.getInstance('0xD27393209C42bdd446F867265ea327e45Ca4FCf6', getWeb3(+chainId) as any)
    case 97:
    case '97':
      return MarketNftContract.getInstance('0x4fc207eaD09d7ABE1fa97D21D3e9A127003603aA', getWeb3(+chainId) as any)
  }
  return null
}
function getWaggleForageContract(chainId: any) {
  chainId = +chainId
  switch (chainId) {
    case 101:
    case 103:
      return SolanaWaggleForageContract.getInstance(chainId)
    case 56:
    case '56':
      return WaggleForageContract.getInstance('0x70eC8Cf0F4CFE68024CB59bEBe2f7Ada402B1fF0', getWeb3(+chainId) as any)

    // hardcode waggle forage contract
    case 97:
    case '97':
    default:
      return WaggleForageContract.getInstance('0xb435CDDF1939EA65D036A1f9957c783E32065513', getWeb3(97) as any)
  }
  return null
}
function throwSolanaAnchorError(error, idl) {
  let translatedErr
  if (error instanceof ProgramError) {
    translatedErr = error
  } else {
    translatedErr = ProgramError.parse(error, parseIdlErrors(idl))
  }
  if (translatedErr === null) {
    if (error instanceof SendTransactionError) {
      const components = error.toString().split('custom program error: ')
      const errorLog = (error.logs || [])
        .filter((x) => x.includes('Program log: '))
        .map((x) => x.replace('Program log: ', ''))
        .join(', ')
      if (components.length || errorLog) {
        throw new Error(`Error=${last(components || [])}, ${errorLog}`)
      }
    }
    throw error
  }
  throw translatedErr
}
function parseIdlErrors(idl) {
  const errors = new Map()
  if (idl.errors) {
    idl.errors.forEach((e) => {
      const msg = e.msg ?? e.name
      errors.set(e.code, msg)
    })
  }
  return errors
}
function etherBatchRequest(web3: Web3, methods: any[]) {
  if (!methods.length) return []
  const batch = new web3.BatchRequest()
  const tasks = Promise.all(
    methods.map(
      (method) =>
        new Promise((resolve, reject) => {
          batch.add(
            method.call.request({}, function (error, result) {
              if (error) {
                console.error('Errror=', method, error)
                reject(error)
              } else {
                resolve(result)
              }
            })
          )
        })
    )
  )
  batch.execute()
  return tasks
}
function fixAnchorAccounts(accounts) {
  const result: { [id: string]: string } = {}
  return Object.entries(accounts).reduce(
    (prev, [key, value]) => ({ ...prev, [key]: (value as any).toString() }),
    result
  )
}
export const blockchainHandler = {
  getChainConfig,
  getWeb3,
  idoContractFactory,
  getSolanaConfig,
  throwSolanaAnchorError,
  ETHER_ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
  etherBatchRequest,
  getMarketNftContract,
  getWaggleForageContract,
  getSolidityIdoContract,
  getSolanaIdoContract,
  getSolanaMainNetRpc,
  fixAnchorAccounts,
}
export type ChainType = 'eth' | 'bsc' | 'sol'
