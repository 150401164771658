import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/projects' },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../modules/dashboard/pages/dashboard.vue'),
  },
  {
    path: '/dashboard/investment-nft',
    name: 'NFT Dashboard',
    component: () => import('../modules/dashboard/pages/nft-dashboard.vue'),
  },
  {
    path: '/submit-kyc',
    name: 'Submit KYC',
    component: () => import('../modules/dashboard/pages/submit-kyc.vue'),
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../modules/ido/pages/ido-pools.vue'),
  },
  {
    path: '/project/:poolid',
    name: 'ProjectDetail',
    component: () => import('../modules/ido/pages/ido-pool-detail.vue'),
  },
  {
    path: '/project/:unicodeName/:refLink',
    name: 'ProjectWhitelist',
    component: () => import('../modules/ido/pages/ido-whitelist.vue'),
  },
  {
    path: '/staking',
    name: 'Staking',
    component: () => import('../modules/staking/pages/staking.vue'),
  },
  {
    path: '/farm-service',
    name: 'StakingList',
    component: () => import('../modules/staking/pages/stakingList.vue'),
  },
  {
    path: '/farm-service/:id',
    name: 'StakingNoTier',
    component: () => import('../modules/staking/pages/stakingNoTier.vue'),
  },
  {
    path: '/seller',
    name: 'SellerBoard',
    component: () => import('../modules/ido/pages/seller-board.vue'),
  },
  {
    path: '/comingSoon',
    name: 'ComingSoon',
    component: () => import('../modules/common/pages/coming-soon.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../modules/common/pages/terms.vue'),
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: () => import('../modules/common/pages/faqs.vue'),
  },
  {
    path: '/allPools',
    name: 'AllPools',
    component: () => import('../modules/ido/pages/all-pools.vue'),
  },
  {
    path: '/allAllocations',
    name: 'AllAllocations',
    component: () => import('../modules/ido/pages/all-allocations.vue'),
  },
  {
    path: '/forage',
    name: 'market',
    component: () => import('../modules/market/pages/market.vue'),
  },
  {
    path: '/investment-card/:nftId',
    name: 'MarketItemDetail',
    component: () => import('../modules/market/pages/market-item-detail.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// router.beforeEach(async (to, from, next) => {
//   await when(() => walletStore.loaded)
//   next()
// })

export default router
