import { ApplyModel } from '@/models/apply-model'
import { FixedPoolModel } from '@/models/fixed-pool-model'
import { TokenPriceModel } from '@/models/token-price-model'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import Axios from 'axios'
import moment from 'moment'
export type ApiRouteType = 'pool' | 'applies' | 'users' | 'investors' | 'logs' | 'transactions' | 'market-statistics'

const axios = Axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT })
const axiosStrapi = Axios.create({
  baseURL: process.env.VUE_APP_API_STRAPI_ENDPOINT,
})

export class ApiHandler<T> {
  constructor(private axios, private route: ApiRouteType) {}

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, { params })
    return res.data
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model)
    return res.data
  }

  async delete(id: any): Promise<T> {
    const res = await this.axios.delete(`${this.route}/${id}`)
    return res.data
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 25, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    if (this.route === 'pool') {
      lst.forEach((element) => {
        if (element.data?.chainId) {
          element.chainId = element.data.chainId
        } else if (isNaN(+element.chainId)) {
          element.chainId = +element.chainId
        }
      })
    }
    return lst
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`)
    } else {
      res = await this.axios.get(`${this.route}`)
    }
    const result = res.data
    if (this.route === 'pool') {
      if (result.data?.chainId) {
        result.chainId = result.data.chainId
      } else if (isNaN(+result.chainId)) {
        result.chainId = +result.chainId
      }
    }
    return result
  }

  async update(id: any, model: T): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.put(`${this.route}/${id}`, model)
    } else {
      res = await this.axios.put(`${this.route}`, model)
    }
    return res.data
  }
}

export class ApiHandlerJWT<T> {
  constructor(private axios, private route: ApiRouteType) {}
  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...axios.defaults.headers,
        Authorization: `Bearer ${walletStore.jwt}`,
      },
    })
    return res.data
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model, {
      headers: {
        ...axios.defaults.headers,
        Authorization: `Bearer ${walletStore.jwt}`,
      },
    })
    return res.data
  }

  async delete(id: any): Promise<T> {
    const res = await this.axios.delete(`${this.route}/${id}`, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }

  async getTier(address: any) {
    const res = await axiosStrapi.get(`get-tier/${address}`)
    return res.data
  }
  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 25, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, {
      params,
      headers: {
        ...axios.defaults.headers,
        // Authorization: `Bearer ${walletStore.jwt}`,
      },
    })
    const lst = res.data
    return lst
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`, { headers: { Authorization: `Bearer ${walletStore.jwt}` } })
    } else {
      res = await this.axios.get(`${this.route}`, { headers: { Authorization: `Bearer ${walletStore.jwt}` } })
    }
    const result = res.data
    return result
  }

  async update(id: any, model?: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.put(`${this.route}/${id}`, model, {
        headers: { Authorization: `Bearer ${walletStore.jwt}` },
      })
    } else {
      res = await this.axios.put(`${this.route}`, model, { headers: { Authorization: `Bearer ${walletStore.jwt}` } })
    }
    return res.data
  }

  async signUp(publicAddress: string) {
    const res = await this.axios.post(`auth/local/register`, { publicAddress })
    return res.data
  }

  async signIn(model) {
    const { publicAddress, signature } = model
    const res = await this.axios.post(`auth/signin`, { publicAddress, signature })
    return res.data
  }

  async createSession(model) {
    const res = await this.axios.post(`investors/create-session`, model, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }
  async mergeKycAddress(params) {
    const { investorId } = params
    const res = await axiosStrapi.put(`merge-kyc/${investorId}`, params, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }

  async checkKycAddresss(params) {
    const res = await axiosStrapi.post(`investors/check-kyc-address`, params, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }

  async checkTierAddress(params) {
    const res = await axiosStrapi.post(`investors/check-tier-address`, params, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }
}

export class ApiService {
  fixedPool = new ApiHandler<FixedPoolModel>(axios, 'pool')
  applies = new ApiHandlerJWT<ApplyModel>(axiosStrapi, 'applies')
  users = new ApiHandlerJWT<any>(axiosStrapi, 'users')
  investors = new ApiHandlerJWT<any>(axiosStrapi, 'investors')
  logs = new ApiHandlerJWT<any>(axiosStrapi, 'logs')
  transactions = new ApiHandlerJWT<any>(axiosStrapi, 'transactions')
  marketStatistics = new ApiHandlerJWT<any>(axiosStrapi, 'market-statistics')

  async getFile(id: any) {
    const res = await axiosStrapi.get(`upload/files/${id}`)
    return res.data
  }

  // async getTokenBalanceOfWallet(address: string, chain: 'BSC' | 'ETH'): Promise<TokenInfoModel[]> {
  //   const apiUrl = `https://api.bscscan.com/api?module=account&action=addresstokenbalance&address=${address}&page=1&offset=100&apikey=${process.env.VUE_APP_BSC_API_KEY}`

  //   // https://api.etherscan.io/api?module=account&action=addresstokenbalance&address=0x6De563767862133854E4AAdb29684fA7Ee5Bae74&page=1&offset=100&apikey=$YHA66AB14D9J8YJ2TGUQ7MVS9ITUEM74DJ
  //   // if (chain === 'ETH')
  //   // apiUrl = ``
  //   const { tokens, time } = localdata.getAccountTokens(address)
  //   let result = tokens
  //   if (!time || moment(time).add(3, 'minutes').isBefore(moment())) {
  //     try {
  //       const res = await this.axios.get(apiUrl)
  //       if (res.data.result) {
  //         result = res.data.result
  //         localdata.saveAccountTokens(address, result)
  //       } else {
  //         result = []
  //       }
  //     } catch (error) {
  //       // using cache
  //     }
  //   }

  //   const results: TokenInfoModel[] = result
  //   return results.map((r) => ({
  //     ...r,
  //     TokenAddress: Web3.utils.toChecksumAddress(r.TokenAddress),
  //     balance: FixedNumber.from(r.TokenQuantity).divUnsafe(FixedNumber.from(`${10 ** +r.TokenDivisor}`)),
  //     Type: 'BSC',
  //   }))
  // }

  async getMarketInfo(chainId: number): Promise<any> {
    const res = await axiosStrapi.get(`/total-market-statistics/${chainId}`)
    return res.data
  }

  async getTokenPrice(address: string): Promise<TokenPriceModel> {
    const apiUrl = `https://api.pancakeswap.info/api/v2/tokens/${address}`
    const {
      data: { data },
    } = await axios.get(apiUrl)

    return {
      ...data,
      price: FixedNumber.fromString(data.price.substr(0, 20)),
    }
  }

  async getTokenMarketData(id: string, vs_currency: string, days: string, tokenSymbol: string) {
    const apiUrl = `https://api.coingecko.com/api/v3/coins/${id}/market_chart?vs_currency=${vs_currency}&days=${days}`
    const {
      data: { prices },
    } = await axios.get(apiUrl)

    const filterdPrices: any = []
    for (let index = 0; index < 10; index++) {
      filterdPrices.push(prices[~~((prices.length * index) / 10)])
    }
    filterdPrices.push(prices[prices.length - 1])

    const zeroDecimals = -Math.floor(Math.log10(Number(filterdPrices[filterdPrices.length - 1][1])) + 1)
    const tokenPrices = filterdPrices.map((price) => {
      if (zeroDecimals >= 2) {
        return +price[1].toFixed(zeroDecimals + 4)
      }

      return +price[1].toFixed(2)
    })
    const xaxisCategories = filterdPrices.map((price) => moment(price[0]).format('DD/MM HH:mm'))

    return {
      xaxisCategories,
      series: [{ name: tokenSymbol, data: tokenPrices }],
    }
  }
}

export const apiService = new ApiService()
