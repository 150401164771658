import { Zero } from '@/constants'
import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { chunk, isNumber, toNumber } from 'lodash-es'
import moment from 'moment'
import Web3 from 'web3'
import { blockchainHandler } from '.'
import fixedswapV4Abi from './abis/fixedswap.v4.json'
import fixedswapV5Abi from './abis/fixedswap.v5.json'
import { Erc20Contract } from './erc20-contract'
import {
  FixedSwapContractPurchase,
  IIdoContract,
  MarketKeyInfo,
  TierConfig,
  TokenVestingSchedule,
} from './ido-contract-interface'
import { MarketNftContract } from './market-nft-solidity'

export class SolidityIdoContract implements IIdoContract {
  contract: any
  erc20Contract!: Erc20Contract
  tradeErc20Contract!: Erc20Contract
  marketNftKey!: MarketNftContract
  erc20Decimals = 18
  tradeErc20Decimals = 18

  _userFee = FixedNumber.from('0')
  _tokensForSale = FixedNumber.from('0')
  _tokensAllocated = FixedNumber.from('0')
  _tokensFund = FixedNumber.from('0')
  _unsoldTokensReedemed = false
  _tradeValue = FixedNumber.from('0')
  _hasWhitelisting = true
  _paused = false
  _redeemConfigs: TokenVestingSchedule[] = []
  _tierConfigs: TierConfig[] = []
  // _redeemConfigsLength = 0
  // _tierConfigsLength = 0
  _participants = 0
  _sellerTax = FixedNumber.from('5')
  _endDate = 0

  _loadTask?: Promise<any>
  _loaded = false

  constructor(public address: string, public web3: Web3, public type = 'v5') {
    this.setContract()
  }

  private setContract() {
    switch (this.type) {
      case 'v4':
        this.contract = new this.web3.eth.Contract(fixedswapV4Abi as any, this.address)
        break
      case 'v5':
      default:
        this.contract = new this.web3.eth.Contract(fixedswapV5Abi as any, this.address)
        break
    }
  }

  injectProvider() {
    const web3 = walletStore.web3 as any
    this.web3 = web3
    this.setContract()
    this.erc20Contract?.injectProvider(web3)
    this.tradeErc20Contract?.injectProvider(web3)
    this.marketNftKey?.injectProvider(web3)
  }

  getPoolTradeTokenAmount(): Promise<FixedNumber> {
    return (this.tradeErc20Contract as any).getTokenAmount(this.address)
  }

  getUserTradeTokenAmount(account: string): Promise<FixedNumber> {
    return (this.tradeErc20Contract as any).getTokenAmount(account)
  }

  getUserIdoTokenAmount(account: string): Promise<FixedNumber> {
    return (this.erc20Contract as any).getTokenAmount(account)
  }

  init(): Promise<any> {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }

    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const web3 = this.web3
        const methods = this.contract.methods
        const [
          erc20,
          erc20Decimals,
          tradeErc20,
          tradeErc20Decimals,
          tokensForSale,
          tokensAllocated,
          tokensFund,
          unsoldTokensReedemed,
          tradeValue,
          hasWhitelisting,
          getRedeemConfigs,
          getTierConfigs,
          getBuyerLength,
          paused,
          userFee,
          sellerTax,
          marketKeyNft,
          endDate,
        ] = (await blockchainHandler.etherBatchRequest(this.web3, [
          methods.erc20(),
          methods.erc20Decimals(),
          methods.tradeErc20(),
          methods.tradeErc20Decimals(),
          methods.tokensForSale(),
          methods.tokensAllocated(),
          methods.tokensFund(),
          methods.unsoldTokensReedemed(),
          methods.tradeValue(),
          methods.hasWhitelisting(),
          methods.getRedeemConfigs(),
          methods.getTierConfigs(),
          methods.getBuyerLength(),
          methods.paused(),
          methods.userFee(),
          methods.sellerTax(),
          methods.marketKeyNft(),
          methods.endDate(),
        ])) as any

        this._endDate = endDate
        this._sellerTax = bnHelper.fromDecimals(sellerTax)
        this._paused = paused
        this._participants = getBuyerLength
        this._tokensForSale = bnHelper.fromDecimals(tokensForSale, erc20Decimals)
        this._tradeValue = bnHelper.fromDecimals(tradeValue)
        this._tokensAllocated = bnHelper.fromDecimals(tokensAllocated, erc20Decimals)
        this._tokensFund = bnHelper.fromDecimals(tokensFund, erc20Decimals)
        this._unsoldTokensReedemed = unsoldTokensReedemed
        this._hasWhitelisting = hasWhitelisting
        this._userFee = bnHelper.fromDecimals(userFee, tradeErc20Decimals)

        this.marketNftKey = MarketNftContract.getInstance(marketKeyNft, web3)

        this.erc20Contract = new Erc20Contract(erc20, web3, erc20Decimals)
        this.erc20Decimals = await this.erc20Contract.decimals()

        this.tradeErc20Contract = new Erc20Contract(tradeErc20, web3, tradeErc20Decimals)
        this.tradeErc20Decimals = await this.tradeErc20Contract.decimals()
        if (web3 !== this.web3) {
          this.erc20Contract.injectProvider(this.web3)
          this.tradeErc20Contract.injectProvider(this.web3)
          this.marketNftKey.injectProvider(this.web3)
        }

        this._redeemConfigs = getRedeemConfigs.map(({ id, date, percentage }) => ({
          id: toNumber(id),
          date: bnHelper.toMoment(date),
          percentage: bnHelper.fromDecimals(percentage),
        }))
        this._tierConfigs = getTierConfigs.map(({ id, maxCost, tokensForSale, tokensAllocated, delayTime }) => ({
          id: toNumber(id),
          maxCost: bnHelper.fromDecimals(maxCost, this.tradeErc20Decimals),
          tokensForSale: bnHelper.fromDecimals(tokensForSale, this.erc20Decimals),
          tokensAllocated: bnHelper.fromDecimals(tokensAllocated, this.erc20Decimals),
          delayTime: toNumber(delayTime) || 0,
        }))
        this._loaded = true
        resolve(null)
      } catch (error) {
        reject(error)
        this._loadTask = undefined
      }
    })
    return this._loadTask
  }

  get poolInfo() {
    return {
      tokenDecimals: this.erc20Decimals,
      tokensForSale: this._tokensForSale,
      tradeValue: this._tradeValue,
      tokensAllocated: this._tokensAllocated,
      tokensFund: this._tokensFund,
      userFee: this._userFee,
      sellerTax: this._sellerTax,
      unsoldTokenReedemed: this._unsoldTokensReedemed,
      hasWhitelisting: this._hasWhitelisting,
      redeemConfigs: [...this._redeemConfigs],
      tierConfigs: [...this._tierConfigs],
      participants: this._participants,
      paused: this._paused,
    }
  }

  async fetchPoolInfo(): Promise<any> {
    const methods = this.contract.methods
    const [tokensAllocated, getBuyerLength, tokensFund, unsoldTokensReedemed, tierConfigs]: any[] =
      await blockchainHandler.etherBatchRequest(this.web3, [
        methods.tokensAllocated(),
        methods.getBuyerLength(),
        methods.tokensFund(),
        methods.unsoldTokensReedemed(),
        methods.getTierConfigs(),
      ])

    this._tokensAllocated = bnHelper.fromDecimals(tokensAllocated, this.erc20Decimals)
    this._participants = toNumber(getBuyerLength)
    this._tokensFund = bnHelper.fromDecimals(tokensFund, this.erc20Decimals)
    this._unsoldTokensReedemed = unsoldTokensReedemed
    this._tierConfigs = (tierConfigs as any[]).map(({ id, maxCost, tokensForSale, tokensAllocated, delayTime }) => ({
      id: toNumber(id),
      maxCost: bnHelper.fromDecimals(maxCost, this.tradeErc20Decimals),
      tokensForSale: bnHelper.fromDecimals(tokensForSale, this.erc20Decimals),
      tokensAllocated: bnHelper.fromDecimals(tokensAllocated, this.erc20Decimals),
      delayTime: toNumber(delayTime) || 0,
    }))
  }

  _userCacheds: {
    [id: string]: {
      boughtAmounts: FixedNumber
      redeemedAmounts: FixedNumber
      isWhitelisted: boolean
      tierId: number
      keyId: number
      keyIdOwner: string
      currentUserTierId: number
      isBuyer: boolean
      paidFee: boolean
      maxUserIdoAmount: FixedNumber
      userTier: TierConfig
      purchases: FixedSwapContractPurchase[]
    }
  } = {}

  async fetchUserConstraints(account: string, force = false) {
    account = this.web3.utils.toChecksumAddress(account)
    if (!this._userCacheds[account] || force) {
      const methods = this.contract.methods

      let tierId = this._userCacheds[account]?.tierId
      let isWhitelisted = this._userCacheds[account]?.isWhitelisted
      let keyId = this._userCacheds[account]?.keyId
      if (!isNumber(tierId)) {
        const results1: any[] = await blockchainHandler.etherBatchRequest(this.web3, [
          methods.getUserTier(account),
          methods.isWhitelisted(account),
          methods.userKeys(account),
        ])
        tierId = toNumber(results1[0])
        isWhitelisted = results1[1]
        keyId = +results1[2]
      } else if (!keyId) {
        keyId = +(await this.contract.methods.userKeys(account).call())
      }

      const calls = [
        methods.getContributeInfoOfKey(keyId),
        methods.getIndividualMaximumAmountOfAccount(account),
        methods.tierConfigs(tierId),
      ]
      if (keyId) {
        calls.push(this.marketNftKey.contract.methods.ownerOf(keyId))
      }

      const [
        {
          0: { boughtAmount: boughtAmountDecimal, redeemedAmount: redeemedAmountDecimal, paidFee },
          1: redeemFinalizeds,
        },
        maxIdoAmountDecimal,
        tier,
        keyIdOwner,
      ]: any[] = await blockchainHandler.etherBatchRequest(this.web3, calls)
      const boughtAmounts = bnHelper.fromDecimals(boughtAmountDecimal, this.erc20Decimals)
      const redeemedAmounts = bnHelper.fromDecimals(redeemedAmountDecimal, this.erc20Decimals)
      let tierTokensForSale = FixedNumber.from('0')
      let tierAllocatedToken = FixedNumber.from('0')
      let maxTierCostAmount = FixedNumber.from('0')
      let tierDelayTime = 0
      if (tier) {
        const { id, maxCost, tokensForSale, tokensAllocated, delayTime } = tier as any
        tierId = toNumber(id)
        maxTierCostAmount = bnHelper.fromDecimals(maxCost, this.tradeErc20Decimals)
        tierTokensForSale = bnHelper.fromDecimals(tokensForSale, this.erc20Decimals)
        tierAllocatedToken = bnHelper.fromDecimals(tokensAllocated, this.erc20Decimals)
        tierDelayTime = delayTime || 0
      }

      const purchases: FixedSwapContractPurchase[] = this._buildPurchases(boughtAmounts, account, redeemFinalizeds)
      this._userCacheds[account] = {
        keyId: +keyId,
        keyIdOwner,
        boughtAmounts,
        redeemedAmounts,
        isWhitelisted: isWhitelisted,
        tierId,
        paidFee,
        currentUserTierId: tierId,
        isBuyer: !boughtAmounts.isZero(),
        maxUserIdoAmount: bnHelper.fromDecimals(maxIdoAmountDecimal, this.erc20Decimals),
        userTier: {
          id: tierId,
          maxCost: maxTierCostAmount,
          tokensForSale: tierTokensForSale,
          tokensAllocated: tierAllocatedToken,
          delayTime: tierDelayTime,
        },
        purchases,
      }
    }
    return {
      ...this._userCacheds[account],
      userTier: { ...this._userCacheds[account].userTier },
      purchases: [...this._userCacheds[account].purchases],
    }
  }

  _userKeyInfoCacheds: {
    [account: string]: MarketKeyInfo[]
  } = {}

  async fetchUserMarketKeyInfos(account: string, force?: boolean): Promise<MarketKeyInfo[]> {
    account = this.web3.utils.toChecksumAddress(account)
    if (!this._userKeyInfoCacheds[account] || force) {
      const ids = await this.marketNftKey.getMyKeyIdsByMarket(account, this.address)
      const methods = this.contract.methods
      const results: any[] = await blockchainHandler.etherBatchRequest(
        this.web3,
        ids.map((keyId) => methods.getContributeInfoOfKey(keyId))
      )
      const infos = results.map((result, index) => {
        return this._buildKeyInfo(result, +ids[index], account)
      })
      this._userKeyInfoCacheds[account] = infos
    }

    return [...this._userKeyInfoCacheds[account]]
  }

  async fetchMarketKeyInfo(keyId: any): Promise<MarketKeyInfo> {
    const [owner, result]: any = await blockchainHandler.etherBatchRequest(this.web3, [
      this.marketNftKey.contract.methods.ownerOf(keyId),
      this.contract.methods.getContributeInfoOfKey(keyId),
    ])
    return this._buildKeyInfo(result, keyId, owner)
  }

  async fetchMarketKeyInfos(keyIds: any[]): Promise<MarketKeyInfo[]> {
    const keyChunks = chunk(keyIds, 100)
    const infos: any[] = []
    for (const idChunk of keyChunks) {
      const results: any[] = await blockchainHandler.etherBatchRequest(
        this.web3,
        idChunk.map((id) => this.contract.methods.getContributeInfoOfKey(id))
      )
      infos.push(...results)
    }
    return infos.map((info, index) => this._buildKeyInfo(info, keyIds[index]))
  }

  private _buildKeyInfo(result: any, id, account = '') {
    const {
      0: { boughtAmount: boughtAmountDecimal, redeemedAmount: redeemedAmountDecimal },
      1: redeemFinalizeds,
    } = result
    const boughtAmounts = bnHelper.fromDecimals(boughtAmountDecimal, this.erc20Decimals)
    const redeemedAmounts = bnHelper.fromDecimals(redeemedAmountDecimal, this.erc20Decimals)

    return {
      id: +id,
      poolAddress: this.address,
      redeemedAmounts,
      boughtAmounts,
      owner: account,
      remain: boughtAmounts.subUnsafe(redeemedAmounts),
      purchases: this._buildPurchases(boughtAmounts, account, redeemFinalizeds),
    }
  }

  private _buildPurchases(boughtAmounts: FixedNumber, account: string, redeemFinalizeds: any[]) {
    const purchases: FixedSwapContractPurchase[] = []
    if (!boughtAmounts.isZero()) {
      let remainedPercentage = FixedNumber.from('100')
      for (const redeem of this._redeemConfigs) {
        remainedPercentage = remainedPercentage.subUnsafe(redeem.percentage)
        const percentage = redeem.percentage.divUnsafe(FixedNumber.from('100'))
        const amount = boughtAmounts.mulUnsafe(percentage)
        purchases.push({
          _id: `${redeem.id}`,
          amount,
          purchaser: account,
          ethAmount: amount.mulUnsafe(this._tradeValue),
          validAfterDate: redeem.date,
          wasFinalized: redeemFinalizeds[redeem.id],
          reverted: false,
          percentage: percentage,
        })
      }
      remainedPercentage = remainedPercentage.divUnsafe(FixedNumber.from('100'))
      if (bnHelper.gt(remainedPercentage, Zero)) {
        purchases.push({
          _id: '-1',
          amount: boughtAmounts.mulUnsafe(remainedPercentage),
          purchaser: account,
          ethAmount: boughtAmounts.mulUnsafe(remainedPercentage).mulUnsafe(this._tradeValue),
          percentage: remainedPercentage,
        } as any)
      }
    }
    return purchases
  }

  isFinalized(): Promise<boolean> {
    return Promise.resolve(bnHelper.toMoment(this._endDate.toString()).isBefore(moment()))
  }

  isOpen(): Promise<boolean> {
    return this.contract.methods.isOpen().call()
  }

  async cost(amount: FixedNumber): Promise<FixedNumber> {
    const amountInDecimals = bnHelper.toDecimalString(amount, this.erc20Decimals)
    return bnHelper.fromDecimals(await this.contract.methods.cost(amountInDecimals).call(), this.tradeErc20Decimals)
  }

  // user
  swap(amount: FixedNumber, account: string): Promise<any> {
    return sendRequest(this.contract.methods.buy(bnHelper.toDecimalString(amount, this.erc20Decimals)), account)
  }
  redeemTokens(keyId: number, purchase_id, account: string): Promise<any> {
    return sendRequest(this.contract.methods.redeemTokens(keyId, purchase_id), account)
  }

  // seller
  isPaused(): Promise<boolean> {
    return this.contract.methods.paused().call()
  }
  fund(amount: string, account): Promise<any> {
    return sendRequest(this.contract.methods.fund(bnHelper.toDecimalString(amount, this.erc20Decimals)), account)
  }
  unpauseContract(account): Promise<any> {
    return sendRequest(this.contract.methods.unpause(), account)
  }
  pauseContract(account): Promise<any> {
    return sendRequest(this.contract.methods.pause(), account)
  }
  withdrawFunds(account): Promise<any> {
    return sendRequest(this.contract.methods.withdrawFunds(), account)
  }
  withdrawUnsoldTokens(account): Promise<any> {
    return sendRequest(this.contract.methods.withdrawUnsoldTokens(), account)
  }
}

function sendRequest(fx, from): Promise<any> {
  return new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve(''))
      .on('error', (error) => reject(error))
  })
}
