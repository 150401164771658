import { Zero } from '@/constants'
import { strEquals } from '@/helpers'
import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import * as anchor from '@project-serum/anchor'
import { BN, Program, Provider, web3 } from '@project-serum/anchor'
import { SendTxRequest } from '@project-serum/anchor/dist/cjs/provider'
import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { PublicKey } from '@solana/web3.js'
import { autorun } from 'mobx'
import { blockchainHandler, WaggleForageOrder } from '.'
import idov1 from './abis/solana-ido.v1.json'
import { WaggleForage } from './abis/waggle_forage'
import waggleForageIDL from './abis/waggle_forage.json'
import { SolanaIdoContract } from './ido-contract-solana'
import { SlpTokenProgram } from './slp-token-contract'
import { WaggleForageInterface } from './waggle-forage-interface'

const { SystemProgram } = web3

const MARKETPLACE_ID = '9pcExS5Bzc1vfA4dPTKZy6ec3UiSJPXCTfADvNc2CBq8'

const IDO_ID = 'GiAnguQGuCMWVxcZviTnLpmSrwnY4YufY7bo4Sc2YegZ'

const cachedProgram: { [chainId: number]: SolanaWaggleForageContract } = {}

export class SolanaWaggleForageContract implements WaggleForageInterface {
  provider: Provider
  idoProgram: Program
  marketProgram: anchor.Program<WaggleForage>
  tradeTokenProgram!: SlpTokenProgram
  tradeDecimals = 9
  sellerTax = Zero
  buyerTax = Zero

  stateSigner!: PublicKey
  stateAccount!: StateAccount

  constructor(public chainId: number) {
    this.provider = blockchainHandler.getSolanaConfig(chainId)
    this.marketProgram = new Program(waggleForageIDL as any, MARKETPLACE_ID.toString(), this.provider)
    this.idoProgram = new Program(idov1 as any, IDO_ID.toString(), this.provider)
    autorun(() => {
      if (walletStore.walletConnected && walletStore.chainId === chainId && walletStore.connectedSolProvider) {
        this.injectProvider(walletStore.connectedSolProvider)
      }
    })
  }

  async getUserTradeTokenAmount(account: string): Promise<FixedNumber> {
    await this.init()
    return await this.tradeTokenProgram.getTokenAmount(account)
  }
  async getAllOpenOrders(): Promise<WaggleForageOrder[]> {
    const orders = await this.marketProgram.account.orderAccount.all()
    return orders.map((o) => {
      const acc = o.account as OrderAccount
      return this._buildOrderAccount(acc)
    })
  }

  private _buildOrderAccount(acc: OrderAccount): WaggleForageOrder {
    return {
      id: acc.contributeId.toNumber(),
      seller: acc.seller.toString(),
      price: bnHelper.fromSolDecimals(acc.price, this.tradeDecimals),
      market: acc.market.toString(),
      buyer: acc.buyer.toString(),
      buyerBidPrice: bnHelper.fromDecimals(acc.buyerBidPrice, this.tradeDecimals),
      buyerTotalPrice: bnHelper.fromDecimals(acc.buyerTotalPrice, this.tradeDecimals),
      createdAt: bnHelper.toMoment(acc.createdAt),
    }
  }

  static getInstance(chainId: number) {
    chainId = +chainId
    if (!cachedProgram[chainId]) cachedProgram[chainId] = new SolanaWaggleForageContract(chainId)
    return cachedProgram[chainId]
  }

  injectProvider(provider: anchor.Provider) {
    this.provider = provider
    this.marketProgram = new Program(waggleForageIDL as any, MARKETPLACE_ID.toString(), this.provider)
    this.idoProgram = new Program(idov1 as any, IDO_ID.toString(), this.provider)
    if (this.stateAccount) {
      this.tradeTokenProgram = new SlpTokenProgram(this.stateAccount.tradeMint, this.provider)
    }
  }

  _loadTask?: Promise<any>
  _loaded = false
  init(): Promise<any> {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }

    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const [stateSigner] = await PublicKey.findProgramAddress([utf8.encode('state')], this.marketProgram.programId)
        this.stateSigner = stateSigner
        this.stateAccount = (await this.marketProgram.account.stateAccount.fetch(stateSigner)) as any
        this.tradeDecimals = this.stateAccount.tradeDecimals
        this.sellerTax = bnHelper.fromSolDecimals(this.stateAccount.taxSeller)
        this.buyerTax = bnHelper.fromSolDecimals(this.stateAccount.taxBuyer)
        this.tradeTokenProgram = new SlpTokenProgram(this.stateAccount.tradeMint, this.provider)
        resolve(null)
      } catch (error) {
        console.error(error)
        reject(error)
        this._loadTask = undefined
      }
    })
    return this._loadTask
  }

  async createOrder(account: string, keyId: any, price: FixedNumber): Promise<any> {
    const { contributeId, contributeSigner, orderSigner, orderBump, vault, vaultBump } = await this.getOrderSigners(
      keyId
    )
    try {
      await this.marketProgram.rpc.createOrder(
        contributeId,
        orderBump,
        vaultBump,
        bnHelper.toSolDecimal(price, this.tradeDecimals),
        {
          accounts: {
            contribute: contributeSigner,
            order: orderSigner,
            state: this.stateSigner,
            authority: account,
            idoProgram: this.idoProgram.programId,
            vault,
            tradeMint: this.stateAccount.tradeMint,
            tokenProgram: TOKEN_PROGRAM_ID,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            systemProgram: SystemProgram.programId,
            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          },
        }
      )
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, waggleForageIDL)
    }
  }

  async buy(account: string, order: WaggleForageOrder, price: FixedNumber): Promise<any> {
    const buyer = order.buyer || account
    const previousBuyer = new PublicKey(buyer)
    const { contributeId, contributeSigner, orderSigner, vault } = await this.getOrderSigners(order.id)
    const txs: Array<SendTxRequest> = []
    const defaultAccounts = {
      contribute: contributeSigner,
      seller: order.seller,
      order: orderSigner,
      state: this.stateSigner,
      creator: this.stateAccount.creator,
      authority: account,
      vault,
      idoProgram: this.idoProgram.programId,
      tradeMint: this.stateAccount.tradeMint,
      buyerVault: await this.tradeTokenProgram.getAssociatedTokenAddress(new PublicKey(account)),
      sellerVault: await this.tradeTokenProgram.getAssociatedTokenAddress(new PublicKey(order.seller)),
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
    }
    const buyTx = this.marketProgram.transaction.buyOrder(
      contributeId,
      bnHelper.toSolDecimal(price, this.tradeDecimals),
      {
        accounts: {
          ...defaultAccounts,
          previousBuyer,
          previousBuyerVault: await this.tradeTokenProgram.getAssociatedTokenAddress(previousBuyer),
        },
      }
    )
    txs.push({ tx: buyTx, signers: [] })
    if (bnHelper.equal(price, order.price)) {
      const claimOrderTx = this.marketProgram.transaction.claimOrder(contributeId, {
        accounts: {
          creatorVault: await this.tradeTokenProgram.getAssociatedTokenAddress(this.stateAccount.creator),
          buyer: account,
          ...defaultAccounts,
        },
      })
      txs.push({ tx: claimOrderTx, signers: [] })
    }
    try {
      await this.provider.sendAll(txs, {})
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, waggleForageIDL)
    }
  }

  async claimOrder(account: string, order: WaggleForageOrder): Promise<any> {
    const { contributeId, contributeSigner, orderSigner, vault } = await this.getOrderSigners(order.id)
    try {
      await this.marketProgram.rpc.claimOrder(contributeId, {
        accounts: {
          contribute: contributeSigner,
          seller: order.seller,
          buyer: order.buyer,
          order: orderSigner,
          state: this.stateSigner,
          creator: this.stateAccount.creator,
          authority: account,
          vault,
          idoProgram: this.idoProgram.programId,
          tradeMint: this.stateAccount.tradeMint,
          sellerVault: await this.tradeTokenProgram.getAssociatedTokenAddress(new PublicKey(account)),
          creatorVault: await this.tradeTokenProgram.getAssociatedTokenAddress(this.stateAccount.creator),
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        },
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, waggleForageIDL)
    }
  }

  async cancelOrder(account: string, order: WaggleForageOrder): Promise<any> {
    const { contributeId, contributeSigner, orderSigner, vault } = await this.getOrderSigners(order.id)
    const txs: Array<SendTxRequest> = []
    const deafaultAccounts = {
      contribute: contributeSigner,
      order: orderSigner,
      state: this.stateSigner,
      seller: account,
      vault,
      idoProgram: this.idoProgram.programId,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    }
    if (order.buyerBidPrice && bnHelper.gt(order.buyerBidPrice, Zero)) {
      const cancelOfferTx = this.marketProgram.transaction.cancelOffer(contributeId, {
        accounts: {
          ...deafaultAccounts,
          creator: this.stateAccount.creator,
          creatorVault: await this.tradeTokenProgram.getAssociatedTokenAddress(this.stateAccount.creator),
          buyer: order.buyer,
          buyerVault: await this.tradeTokenProgram.getAssociatedTokenAddress(new PublicKey(order.buyer)),
          tradeMint: this.stateAccount.tradeMint,
          authority: account,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        },
      })
      txs.push({ tx: cancelOfferTx, signers: [] })
    }
    const cancelOrderTx = this.marketProgram.transaction.cancelOrder(contributeId, {
      accounts: { ...deafaultAccounts },
    })
    txs.push({ tx: cancelOrderTx, signers: [] })
    try {
      await this.provider.sendAll(txs, {})
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, waggleForageIDL)
    }
  }

  async cancelOffer(account: string, order: WaggleForageOrder): Promise<any> {
    const { contributeId, contributeSigner, orderSigner, vault } = await this.getOrderSigners(order.id)
    try {
      await this.marketProgram.rpc.cancelOffer(contributeId, {
        accounts: {
          contribute: contributeSigner,
          order: orderSigner,
          state: this.stateSigner,
          seller: order.seller,
          idoProgram: this.idoProgram.programId,
          vault,
          creator: this.stateAccount.creator,
          creatorVault: await this.tradeTokenProgram.getAssociatedTokenAddress(this.stateAccount.creator),
          buyer: order.buyer,
          buyerVault: await this.tradeTokenProgram.getAssociatedTokenAddress(new PublicKey(order.buyer)),
          tradeMint: this.stateAccount.tradeMint,
          authority: account,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        },
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, waggleForageIDL)
    }
  }

  async getOrderSigners(keyId: any) {
    const contributeId = new BN(keyId.toString())
    const [contributeSigner, contributeBump] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('contribute'), utf8.encode(contributeId.toString())],
      this.idoProgram.programId
    )
    const [orderSigner, orderBump] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('order'), utf8.encode(contributeId.toString())],
      this.marketProgram.programId
    )
    const [vault, vaultBump] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('vault'), utf8.encode(contributeId.toString())],
      this.marketProgram.programId
    )

    return { contributeId, contributeSigner, contributeBump, orderSigner, orderBump, vault, vaultBump }
  }

  async getNftInfo(keyId: any): Promise<any> {
    const keyInfo = await SolanaIdoContract.getMarketKeyInfo(this.chainId, keyId)
    let order: WaggleForageOrder | null = null
    if (strEquals(keyInfo.owner, this.stateSigner)) {
      const { orderSigner } = await this.getOrderSigners(keyId)
      order = this._buildOrderAccount((await this.marketProgram.account.orderAccount.fetch(orderSigner)) as any)
    }
    return {
      selling: !!order,
      owner: order?.seller || keyInfo.owner,
      keyInfo,
      market: keyInfo.poolAddress,
      order,
    }
  }
}

interface StateAccount {
  authority: PublicKey
  creator: PublicKey
  bump: number
  taxBuyer: BN
  taxSeller: BN
  tradeMint: PublicKey
  tradeDecimals: number
  paused: boolean
  totalOrder: BN
  successedOrder: BN
  totalTrade: BN
}

interface OrderAccount {
  seller: PublicKey
  market: PublicKey
  buyer: PublicKey
  buyerBidPrice: BN
  buyerTotalPrice: BN
  bump: number
  contributeId: BN
  price: BN
  createdAt: BN
  vault: PublicKey
  vaultBump: number
}
