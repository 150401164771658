import { bnHelper } from '@/helpers/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import * as anchor from '@project-serum/anchor'
import { BN, Program, Provider, web3 } from '@project-serum/anchor'
import { SendTxRequest } from '@project-serum/anchor/dist/cjs/provider'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { ENV as SOL_CHAINID } from '@solana/spl-token-registry'
import { PublicKey } from '@solana/web3.js'
import { round } from 'lodash'
import moment, { Duration, duration, Moment } from 'moment'
import { blockchainHandler } from '.'
import farmv1 from './abis/farm-solana.v1.json'
import { SlpTokenProgram } from './slp-token-contract'

const utf8 = anchor.utils.bytes.utf8
const { SystemProgram } = web3

const FARM_ID = new PublicKey('2PB1GQHQTQtj6WgPTjNnmVc6r8WP9EUQw4SoniABCp1v')

export interface RewardConfig {
  duration: moment.Duration
  inseconds: number
  extraPercentage: FixedNumber
  text: string
  tooltip: string
  boost: FixedNumber
}

let mainContract: FarmContractSolana
let devContract: FarmContractSolana
export class FarmContractSolana {
  program: anchor.Program
  rewardToken!: SlpTokenProgram
  kycTradeToken!: SlpTokenProgram
  stateAccount?: FarmStateAccount
  poolAccount?: FarmPoolAccount
  rewardConfigAccount?: RewardConfigAccount
  kycConfigAccount?: KycConfigAccount

  private constructor(public provider: Provider) {
    this.program = new Program(farmv1 as any, FARM_ID.toString(), provider)
  }

  static getInstance(provider?: Provider) {
    if (!mainContract) {
      const mainProvider = blockchainHandler.getSolanaConfig(SOL_CHAINID.MainnetBeta)
      const devProvider = blockchainHandler.getSolanaConfig(SOL_CHAINID.Devnet)
      mainContract = new FarmContractSolana(mainProvider)
      devContract = new FarmContractSolana(devProvider)
    }

    switch ((provider?.connection as any)?._rpcEndpoint) {
      case (mainContract.provider.connection as any)?._rpcEndpoint:
        return mainContract
      case (devContract.provider.connection as any)?._rpcEndpoint:
        return devContract
    }
    return process.env.VUE_APP_FARM_NETWORK === 'production' ? mainContract : devContract
  }

  injectProvider(provider) {
    this.provider = provider
    this.program = new Program(farmv1 as any, FARM_ID.toString(), provider)
    if (this.rewardToken) {
      this.rewardToken = new SlpTokenProgram(this.rewardToken.mint, this.provider)
    }
    if (this.kycTradeToken) {
      this.kycTradeToken = new SlpTokenProgram(this.kycTradeToken.mint, this.provider)
    }
  }

  private _loaded = false
  async loadIfNeed() {
    if (!this._loaded) {
      this._loaded = true
      await this.load()
    }
  }

  clearUser() {
    this.userInfo = undefined
  }

  async getRewardVaultAmount() {
    if (!this.stateAccount) return FixedNumber.from('0')
    const info = await this.rewardToken.token.getAccountInfo(this.stateAccount.rewardVault)
    return bnHelper.fromSolDecimals(info.amount)
  }

  async loadKycConfig() {
    try {
      this.kycConfigAccount = await this.getKycConfigAccount()
      this.kycTradeToken = new SlpTokenProgram(this.kycConfigAccount.mint, this.provider)
    } catch (e) {
      //
    }
  }

  async load() {
    await this.loadKycConfig()
    this.stateAccount = await this.getStateAccount()
    this.rewardToken = new SlpTokenProgram(this.stateAccount.rewardMint, this.provider)
    this.poolAccount = await this.getPoolAccount()
    await this.getRewardConfigs()
  }
  async createKycUser(account: string) {
    try {
      const authority = account
      const { kycUserBump, kycUserSigner } = await this.getKycUserSigner(account)
      const kycState = await this.getKycSigner()
      const userVault = (await this.kycTradeToken.getAssociatedTokenAddress(new PublicKey(account))).toString()
      const receiverVault = (
        await this.kycTradeToken.getAssociatedTokenAddress(this.kycConfigAccount!.receiverFund)
      ).toString()
      const tx = this.program.transaction.createKycUser(kycUserBump, {
        accounts: blockchainHandler.fixAnchorAccounts({
          state: kycState,
          user: kycUserSigner,
          mint: this.kycConfigAccount?.mint,
          authority,
          userVault,
          receiverVault,
          tokenProgram: TOKEN_PROGRAM_ID.toString(),
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
          systemProgram: SystemProgram.programId.toString(),
        }),
      })

      await this.provider.send(tx, [], {})
    } catch (error) {
      console.log('err', error)
      blockchainHandler.throwSolanaAnchorError(error, farmv1)
    }
  }

  async stake(account: string, amount: string, lockInSeconds: number) {
    const authority = account
    const stakeAmount = bnHelper.toSolDecimal(amount)
    const hasUser = !!(await this.getUserAccount(account))
    const txs: Array<SendTxRequest> = []
    const { userAccount, bump } = await this.getUserSigner(account)
    const state = await this.getStateSigner()
    const pool = await this.getPoolSigner()
    if (!hasUser) {
      const tx = this.program.transaction.createUser(bump, {
        accounts: blockchainHandler.fixAnchorAccounts({
          user: userAccount,
          state,
          pool,
          authority,
          tokenProgram: TOKEN_PROGRAM_ID.toString(),
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
          systemProgram: SystemProgram.programId.toString(),
        }),
      })
      txs.push({ tx, signers: [] })
    }
    const stakeTx = this.program.transaction.stake(stakeAmount, new BN(lockInSeconds), {
      accounts: blockchainHandler.fixAnchorAccounts({
        mint: (this.poolAccount as any).mint.toString(),
        poolVault: (this.poolAccount as any).vault.toString(),
        userVault: (await this.rewardToken.getAssociatedTokenAddress(new PublicKey(authority))).toString(),
        extraRewardAccount: await this.getRewardConfigSigner(),
        user: userAccount,
        state,
        pool,
        authority: authority,
        tokenProgram: TOKEN_PROGRAM_ID.toString(),
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
        systemProgram: SystemProgram.programId.toString(),
      }),
    })
    txs.push({ tx: stakeTx, signers: [] })
    try {
      await this.provider.sendAll(txs, {})
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmv1)
    }
  }

  async unstake(account: string, amount: string) {
    const authority = new PublicKey(account)
    const unstakeAmount = bnHelper.toSolDecimal(amount)
    const { userAccount } = await this.getUserSigner(account)
    const state = await this.getStateSigner()
    const pool = await this.getPoolSigner()
    try {
      const hasVault = await this.rewardToken.hasAssociatedTokenAddress(account)
      const transaction = new web3.Transaction()
      if (!hasVault) {
        const createAta = await this.rewardToken.getCreateAssociatedTokenAddressInstruction(account)
        transaction.add(createAta)
      }

      if (!this.poolAccount) throw 'poolAccount is undefined'
      const unstakTx = this.program.transaction.unstake(unstakeAmount, {
        accounts: blockchainHandler.fixAnchorAccounts({
          mint: this.poolAccount.mint.toString(),
          poolVault: this.poolAccount.vault.toString(),
          extraRewardAccount: (await this.getRewardConfigSigner()).toString(),
          userVault: await this.rewardToken.getAssociatedTokenAddress(authority),
          user: userAccount,
          state,
          pool,
          authority: authority,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: SystemProgram.programId,
        }),
      })
      transaction.add(unstakTx)
      await this.provider.send(transaction, [], {})
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmv1)
    }
  }

  async harvest(account: string) {
    const authority = new PublicKey(account)
    const { userAccount } = await this.getUserSigner(account)
    const state = await this.getStateSigner()
    const pool = await this.getPoolSigner()
    try {
      if (!this.poolAccount) throw 'poolAccount is undefined'
      if (!this.stateAccount) throw 'stateAccount is undefined'

      const hasVault = await this.rewardToken.hasAssociatedTokenAddress(account)
      const transaction = new web3.Transaction()
      if (!hasVault) {
        const createAta = await this.rewardToken.getCreateAssociatedTokenAddressInstruction(account)
        transaction.add(createAta)
      }

      const harvestTx = this.program.transaction.harvest({
        accounts: blockchainHandler.fixAnchorAccounts({
          mint: this.poolAccount.mint,
          rewardVault: this.stateAccount.rewardVault,
          extraRewardAccount: await this.getRewardConfigSigner(),
          userVault: await this.rewardToken.getAssociatedTokenAddress(authority),
          user: userAccount,
          state: state,
          pool: pool,
          authority,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: SystemProgram.programId,
        }),
      })
      transaction.add(harvestTx)
      await this.provider.send(transaction, [], {})
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmv1)
    }
  }

  async fund(account: string, amount: FixedNumber) {
    const authority = new PublicKey(account)
    const state = await this.getStateSigner()
    const pool = await this.getPoolSigner()
    try {
      if (!this.stateAccount) throw 'stateAccount is undefined'
      await this.program.rpc.fundRewardToken(bnHelper.toSolDecimal(amount), {
        accounts: blockchainHandler.fixAnchorAccounts({
          pool,
          state,
          rewardVault: this.stateAccount.rewardVault,
          userVault: await this.rewardToken.getAssociatedTokenAddress(authority),
          authority,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: SystemProgram.programId,
        }),
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, farmv1)
    }
  }

  getPoolInfor() {
    // const stateInfo = await this.getStateAccount()
    // const poolInfo = await this.getPoolAccount()
    if (!this.poolAccount) throw 'poolAccount is undefined'
    if (!this.stateAccount) throw 'stateAccount is undefined'
    const totalStakedAmount = bnHelper.fromSolDecimals(this.poolAccount.amount)
    const farmRate = bnHelper.fromSolDecimals(this.stateAccount.tokenPerSecond)
    const tokenOnYear = farmRate.mulUnsafe(FixedNumber.from(365 * 24 * 60 * 60))
    const apy = totalStakedAmount.isZero()
      ? totalStakedAmount
      : tokenOnYear.divUnsafe(totalStakedAmount).mulUnsafe(FixedNumber.from('100'))
    return { apy, totalStakedAmount }
  }

  async getUserInfo(address: string) {
    const user = await this.getUserSigner(address)
    let lastStakeTime: Moment | null = null
    let lockDuration: Duration | null = null
    try {
      this.userInfo = (await this.program.account.farmPoolUserAccount.fetch(user.userAccount)) as FarmUserAccount
    } catch {
      this.userInfo = undefined
    }
    const userInfo = this.userInfo
    if (userInfo) {
      if (!userInfo.lastStakeTime.isZero()) {
        // console.log(userInfo.lastStakeTime.toString())
        lastStakeTime = bnHelper.toMoment(userInfo.lastStakeTime)
      }
      if (!userInfo.lockDuration.isZero()) {
        lockDuration = duration(userInfo.lockDuration.toNumber(), 'second')
      }
    }
    const amount = bnHelper.fromSolDecimals(this.userInfo?.amount || '0')
    return { amount, lastStakeTime, lockDuration }
  }

  userInfo: FarmUserAccount | undefined = undefined

  async getPendingRewardAmount(address: string) {
    const user = await this.getUserSigner(address)
    let amount = FixedNumber.from(0)
    try {
      const state = this.stateAccount
      const pool = this.poolAccount
      if (!pool || !state) throw 'pool or state is undefined'
      if (!this.userInfo?.authority || !this.userInfo.authority.equals(new PublicKey(address))) {
        this.userInfo = (await this.program.account.farmPoolUserAccount.fetch(user.userAccount)) as FarmUserAccount
      }
      const userInfo = this.userInfo
      if (!userInfo) throw 'userInfo is undefined'
      let accRewardPerShare = pool.accRewardPerShare
      if (!pool.amount.isZero() && !state.totalPoint.isZero()) {
        const seconds = new BN(Date.now() / 1000).sub(pool.lastRewardTime)
        const more = state.tokenPerSecond
          .mul(seconds)
          .mul(pool.point)
          .mul(new BN(10).pow(new BN(11)))
          .div(state.totalPoint)
          .div(pool.amount)
        accRewardPerShare = accRewardPerShare.add(more)
      }
      let pending = userInfo.amount
        .mul(accRewardPerShare)
        .div(new BN(10).pow(new BN(11)))
        .sub(userInfo.rewardDebt)
      const configs = await this.getRewardConfigs()
      const extraPercentage =
        [...configs].reverse().find((x) => userInfo.lockDuration.toNumber() >= x.duration.asSeconds())
          ?.extraPercentage || FixedNumber.from('0')
      const extraReward = pending.mul(new BN(extraPercentage.toUnsafeFloat())).div(new BN(100))
      // console.log(extraReward.toNumber(), userInfo.extraReward.toNumber())
      pending = pending.add(userInfo.rewardAmount).add(userInfo.extraReward).add(extraReward)
      if (pending.isNeg()) pending = new BN(0)
      amount = bnHelper.fromSolDecimals(pending)
    } catch (e) {
      console.log(e)
    }
    return amount
  }

  async getRewardConfigs() {
    if (!this.rewardConfigAccount) {
      this.rewardConfigAccount = (await this.program.account.extraRewardsAccount.fetch(
        await this.getRewardConfigSigner()
      )) as RewardConfigAccount
    }
    return this.rewardConfigAccount.configs.map((c) => {
      const inseconds = duration(c.duration.toNumber(), 'second')
      const inmonths = round(inseconds.asMonths())
      let text = `${inmonths}M`
      let tooltip = `${inmonths} months`
      if (inmonths === 1) tooltip = tooltip.replace('months', 'month')
      if (inmonths >= 12) {
        text = `1Y`
        tooltip = `1 year`
      }
      const extraPercentage = bnHelper.fromSolDecimals(c.extraPercentage)
      const boost = FixedNumber.from('100').addUnsafe(extraPercentage).divUnsafe(FixedNumber.from('100'))
      return {
        duration: inseconds,
        inseconds: inseconds.asSeconds(),
        extraPercentage,
        boost,
        text,
        tooltip,
      } as RewardConfig
    })
  }

  async getUserRewardBalance(account: string) {
    try {
      return await this.rewardToken.getTokenAmount(account)
    } catch (error) {
      console.log(error)
      return FixedNumber.from('0')
    }
  }

  async getUserAccount(account) {
    const user = await this.getUserSigner(account)
    try {
      return (await this.program.account.farmPoolUserAccount.fetch(user.userAccount)) as FarmUserAccount
    } catch (error) {
      return null
    }
  }
  async getStateAccount() {
    const stateSigner = await this.getStateSigner()
    const info = (await this.program.account.stateAccount.fetch(stateSigner)) as FarmStateAccount
    return info
  }
  async getPoolAccount() {
    return (await this.program.account.farmPoolAccount.fetch(await this.getPoolSigner())) as FarmPoolAccount
  }
  async getRewardConfigSigner() {
    const [extraRewardSigner] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('extra')],
      this.program.programId
    )
    return extraRewardSigner.toString()
  }
  async getStateSigner() {
    const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress([utf8.encode('state')], this.program.programId)
    return _poolSigner.toString()
  }
  async getPoolSigner() {
    const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress(
      [this.rewardToken.mint.toBuffer()],
      this.program.programId
    )
    return _poolSigner.toString()
  }
  async getUserSigner(address: string) {
    const accountId = new PublicKey(address)
    const [userAccount, bump] = await PublicKey.findProgramAddress(
      [new PublicKey(await this.getPoolSigner()).toBuffer(), accountId.toBuffer()],
      this.program.programId
    )
    return { userAccount: userAccount.toString(), bump }
  }
  async getKycSigner() {
    const [kycSigner] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('kycConfig')],
      this.program.programId
    )
    return kycSigner.toString()
  }
  async getKycConfigAccount() {
    const kycSigner = await this.getKycSigner()
    const info = (await this.program.account.kycConfigAccount.fetch(kycSigner)) as KycConfigAccount
    return info
  }
  async getKycUserSigner(account) {
    const accountId = new PublicKey(account)
    const [kycUserSigner, kycUserBump] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('kycUser'), accountId.toBuffer()],
      this.program.programId
    )
    return { kycUserSigner: kycUserSigner.toString(), kycUserBump }
  }
  async getKycUserAccount(account) {
    const { kycUserSigner } = await this.getKycUserSigner(account)
    const info = (await this.program.account.kycUserAccount.fetch(kycUserSigner)) as KycUserAccount
    return info
  }
  async getUserKycTradeTokenBalance(account: string) {
    try {
      return await this.kycTradeToken.getTokenAmount(account)
    } catch (error) {
      console.log(error)
      return FixedNumber.from('0')
    }
  }
}

export interface FarmStateAccount {
  authority: PublicKey
  rewardMint: PublicKey
  rewardVault: PublicKey
  bump: number
  totalPoint: BN
  startTime: BN
  tokenPerSecond: BN
}
export interface KycConfigAccount {
  authority: PublicKey
  mint: PublicKey
  bump: number
  fee: BN
  totalFee: BN
  receiverFund: PublicKey
}

export interface KycUserAccount {
  authority: PublicKey
  bump: number
  paid_fee: BN
}

export interface FarmPoolAccount {
  bump: number
  authority: PublicKey
  amount: BN
  mint: PublicKey
  vault: PublicKey
  point: BN
  lastRewardTime: BN
  accRewardPerShare: BN
  amountMultipler: BN
  totalUser: BN
}

export interface FarmUserAccount {
  bump: number
  pool: PublicKey
  authority: PublicKey
  amount: BN
  rewardAmount: BN
  rewardDebt: BN
  lastStakeTime: BN
  lockDuration: BN
  extraReward: BN
}

export interface RewardConfigAccount {
  authority: PublicKey
  configs: { duration: BN; extraPercentage: BN }[]
}
