import { Zero } from '@/constants'
import { strEquals } from '@/helpers'
import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { chunk } from 'lodash'
import { autorun } from 'mobx'
import Web3 from 'web3'
import { blockchainHandler, WaggleForageOrder } from '.'
import waggleForageAbi from './abis/waggleforage.abi.json'
import { Erc20Contract } from './erc20-contract'
import { MarketNftContract } from './market-nft-solidity'
import { WaggleForageInterface } from './waggle-forage-interface'

const cacheds: {
  [address: string]: WaggleForageContract
} = {}

export class WaggleForageContract implements WaggleForageInterface {
  contract: any

  tradeErc20Contract!: Erc20Contract
  marketNftKey!: MarketNftContract
  router = ''
  tradeErc20Decimals = 18
  sellerTax = FixedNumber.from('0')
  buyerTax = FixedNumber.from('0')

  private constructor(public address: string, public web3: Web3) {
    this.contract = new web3.eth.Contract(waggleForageAbi as any, address)
    autorun(() => {
      if (walletStore.walletConnected && walletStore.chainId === (web3 as any).chainId) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.injectProvider(walletStore.web3!)
      }
    })
  }

  static getInstance(address: string, web3?: Web3) {
    if (!web3) throw 'web3 is undefined'
    address = Web3.utils.toChecksumAddress(address)
    if (!cacheds[address]) cacheds[address] = new WaggleForageContract(address, web3)
    return cacheds[address]
  }

  injectProvider(web3: Web3) {
    this.web3 = web3
    this.contract = new web3.eth.Contract(waggleForageAbi as any, this.address)
    this.tradeErc20Contract?.injectProvider(this.web3)
    this.marketNftKey?.injectProvider(this.web3)
  }

  async getUserTradeTokenAmount(account: string): Promise<FixedNumber> {
    await this.init()
    return (this.tradeErc20Contract as any).getTokenAmount(account)
  }

  _loadTask?: Promise<any>
  _loaded = false

  async init() {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }

    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const web3 = this.web3
        const methods = this.contract.methods
        this._loaded = true
        const [tradeErc20, tradeErc20Decimals, sellerTax, buyerTax, marketKeyNft, router] =
          (await blockchainHandler.etherBatchRequest(this.web3, [
            methods.tradeToken(),
            methods.tradeTokenDecimals(),
            methods.sellerTax(),
            methods.buyerTax(),
            methods.waggleKeyNft(),
            methods.router(),
          ])) as any
        this.sellerTax = bnHelper.fromDecimals(sellerTax || Zero)
        this.buyerTax = bnHelper.fromDecimals(buyerTax || Zero)
        this.router = router
        this.marketNftKey = MarketNftContract.getInstance(marketKeyNft, web3)
        this.tradeErc20Contract = new Erc20Contract(tradeErc20, web3, tradeErc20Decimals)
        this.tradeErc20Decimals = await this.tradeErc20Contract.decimals()
        if (web3 !== this.web3) {
          this.tradeErc20Contract.injectProvider(this.web3)
          this.marketNftKey.injectProvider(this.web3)
        }
        resolve(null)
      } catch (error) {
        reject(error)
        this._loadTask = undefined
      }
    })
    return this._loadTask
  }

  isApproved = async (account) => {
    if (!this.router) throw 'Router contract is undefined'
    return await this.tradeErc20Contract.isApproved(account, this.router)
  }
  approve = async (account) => {
    if (!this.router) throw 'Router contract is undefined'
    return await this.tradeErc20Contract.approve(account, this.router)
  }

  createOrder(account: string, keyId: string, price: FixedNumber): Promise<any> {
    return sendRequest(
      this.contract.methods.createOrder(keyId, bnHelper.toDecimalString(price, this.tradeErc20Decimals)),
      account
    )
  }

  buy(account: string, order: WaggleForageOrder, price: FixedNumber): Promise<any> {
    return sendRequest(
      this.contract.methods.buy(order.id, bnHelper.toDecimalString(price, this.tradeErc20Decimals)),
      account
    )
  }

  claimOrder(account: string, order: WaggleForageOrder): Promise<any> {
    return sendRequest(this.contract.methods.claimOrder(order.id), account)
  }

  cancelOffer(account: string, order: WaggleForageOrder): Promise<any> {
    return sendRequest(this.contract.methods.cancelOffer(order.id), account)
  }

  cancelOrder(account: string, order: WaggleForageOrder): Promise<any> {
    return sendRequest(this.contract.methods.cancelOrder(order.id), account)
  }

  async getAllOpenOrders(): Promise<WaggleForageOrder[]> {
    const methods = this.contract.methods
    const ids = await methods.getAllOpeningOrderIds().call()
    const idChunks = chunk(ids, 500)
    const orders: any[] = []
    for (const idChunk of idChunks) {
      try {
        let results: any[] = await methods.getOrderByIds(idChunk).call()
        results = results.map((x, index) => ({ ...x, id: idChunk[index] }))
        orders.push(...results)
      } catch (error) {
        console.error(error)
      }
    }
    return orders.map(this.toWaggleForageOrder.bind(this))
  }

  async getNftInfo(keyId: any): Promise<any> {
    const { market } = await this.marketNftKey.contract.methods.keyInfos(keyId).call()
    const idoContract = blockchainHandler.getSolidityIdoContract(market, this.web3)
    await idoContract.init()
    const keyInfo = await idoContract.fetchMarketKeyInfo(keyId)
    let order: WaggleForageOrder | undefined
    if (strEquals(keyInfo.owner, this.address)) {
      order = this.toWaggleForageOrder(await this.contract.methods.orders(keyId).call())
      order.id = keyId
      if (!order.seller) {
        // seem be transfer to contract directly
        order = undefined
      }
    }
    return {
      selling: !!order,
      owner: order?.seller || keyInfo.owner,
      keyInfo,
      market,
      order,
    }
  }

  toWaggleForageOrder({ seller, price, market, buyer, createdAt, buyerBidPrice, buyerTotalPrice, id }) {
    return {
      id,
      seller,
      price: bnHelper.fromDecimals(price, this.tradeErc20Decimals),
      market,
      buyer,
      buyerBidPrice: bnHelper.fromDecimals(buyerBidPrice, this.tradeErc20Decimals),
      buyerTotalPrice: bnHelper.fromDecimals(buyerTotalPrice, this.tradeErc20Decimals),
      createdAt: bnHelper.toMoment(createdAt),
    } as WaggleForageOrder
  }
}
function sendRequest(fx, from): Promise<any> {
  return new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve(''))
      .on('error', (error) => reject(error))
  })
}
