import { Zero } from '@/constants'
import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import * as anchor from '@project-serum/anchor'
import { BN, Program, Provider, web3 } from '@project-serum/anchor'
import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { PublicKey } from '@solana/web3.js'
import { flatten, isNumber } from 'lodash'
import { blockchainHandler } from '.'
import idov1 from './abis/solana-ido.v1.json'
import { FarmContractSolana } from './farm-contract-solana'
import {
  FixedSwapContractPurchase,
  IIdoContract,
  MarketKeyInfo,
  TierConfig,
  TokenVestingSchedule,
} from './ido-contract-interface'
import { SlpTokenProgram } from './slp-token-contract'

const { SystemProgram } = web3

const IDO_ID = 'GiAnguQGuCMWVxcZviTnLpmSrwnY4YufY7bo4Sc2YegZ'

const cachedProgram: { [chainId: string]: SolanaIdoContract } = {}

export class SolanaIdoContract implements IIdoContract {
  poolId!: string
  program: Program
  idoTokenProgram!: SlpTokenProgram
  tradeTokenProgram!: SlpTokenProgram

  idoDecimals = 9
  tradeDecimals = 9

  _userFee = FixedNumber.from('0')
  _tokensForSale = FixedNumber.from('0')
  _tokensAllocated = FixedNumber.from('0')
  _tokensFund = FixedNumber.from('0')
  _tradeValue = FixedNumber.from('0')
  _redeemConfigs: TokenVestingSchedule[] = []
  _tierConfigs: TierConfig[] = []

  poolAccount!: PoolAccountType

  _loadTask?: Promise<any>
  _loaded = false

  constructor(public address: string, public provider: Provider) {
    this.poolId = address
    this.program = new Program(idov1 as any, IDO_ID, provider)
  }

  static async getMarketKeyInfo(chainId: any, id: any) {
    chainId = chainId.toString()
    let program = cachedProgram[chainId]
    if (!program) {
      const provider = blockchainHandler.getSolanaConfig(+chainId)
      program = new SolanaIdoContract('', provider)
      cachedProgram[chainId] = program
    }
    const [contributeSigner]: any[] = await program.getContributeSigners(id)
    const contributeAccount = await program.getContributeAccount(contributeSigner)
    if (!contributeAccount) throw 'contributeAccount is undefined'
    const ido = blockchainHandler.getSolanaIdoContract(contributeAccount.poolAccount.toString(), program.provider)
    await ido.init()
    // const marketInfo = await ido.fetchMarketKeyInfo(id)
    return (ido as SolanaIdoContract)._buildKeyInfo(contributeAccount)
  }

  injectProvider() {
    this.provider = walletStore.connectedSolProvider as any
    this.program = new Program(idov1 as any, IDO_ID, this.provider)
    if (this.poolAccount) {
      this.idoTokenProgram = new SlpTokenProgram(this.poolAccount.idoMint, this.provider)
      this.tradeTokenProgram = new SlpTokenProgram(this.poolAccount.tradeMint, this.provider)
    }
  }

  async transferMarketKey(account: string, keyId: number, destination: string) {
    const [contributeSigner]: any[] = await this.getContributeSigners(keyId)
    try {
      await this.program.rpc.transferContribute(new BN(keyId), {
        accounts: blockchainHandler.fixAnchorAccounts({
          contribute: contributeSigner.toString(),
          destination: destination,
          authority: account,
        }),
      })
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, idov1)
    }
  }

  allContributes?: MarketKeyInfo[]
  async fetchMarketKeyInfos(keyIds: any): Promise<MarketKeyInfo[]> {
    if (!this.allContributes) {
      const contributes = await this.program.account.contributeAccount.all([
        {
          memcmp: {
            offset: 8,
            bytes: this.poolId,
          },
        },
      ] as any)
      this.allContributes = contributes.map((c) => this._buildKeyInfo(c.account as ContributeAccountType))
    }
    return (this.allContributes || []).filter((c) => keyIds.includes(c.id))
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchMarketKeyInfo(account: string, force?: boolean): Promise<MarketKeyInfo> {
    throw new Error('Method not implemented.')
  }

  getUserTradeTokenAmount(account: string): Promise<FixedNumber> {
    return this.tradeTokenProgram.getTokenAmount(account)
  }

  async getPoolTradeTokenAmount(): Promise<FixedNumber> {
    const poolAccount = this.poolAccount
    const tradeDecimals = poolAccount.tradeDecimals
    const info = await this.tradeTokenProgram.token.getAccountInfo(poolAccount.tradePoolVault)
    return bnHelper.fromSolDecimals(info.amount, tradeDecimals)
  }

  getUserIdoTokenAmount(account: string): Promise<FixedNumber> {
    return this.idoTokenProgram.getTokenAmount(account)
  }

  init(): Promise<any> {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }
    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const poolAccount: PoolAccountType = await this.getPoolAccount()
        this.poolAccount = poolAccount
        this.idoDecimals = poolAccount.idoDecimals
        this.tradeDecimals = poolAccount.tradeDecimals
        this.idoTokenProgram = new SlpTokenProgram(poolAccount.idoMint, this.provider)
        this.tradeTokenProgram = new SlpTokenProgram(poolAccount.tradeMint, this.provider)

        this._tokensForSale = bnHelper.fromSolDecimals(poolAccount.tokensForSale, this.idoDecimals)
        this._tokensAllocated = bnHelper.fromSolDecimals(poolAccount.allocatedToken, this.idoDecimals)
        this._tokensFund = bnHelper.fromSolDecimals(poolAccount.tokensFund, this.idoDecimals)
        this._tradeValue = bnHelper.fromSolDecimals(poolAccount.tradeValue)
        this._userFee = bnHelper.fromSolDecimals(poolAccount.userFee, this.tradeDecimals)

        this._redeemConfigs = poolAccount.redeemConfigs.map(({ id, date, percentage }) => ({
          id,
          date: bnHelper.toMoment(date),
          percentage: bnHelper.fromSolDecimals(percentage),
        }))
        this._tierConfigs = poolAccount.tiers.map(({ id, tokensForSale, maxCost, allocatedToken, delayTime }) => ({
          id,
          tokensForSale: bnHelper.fromSolDecimals(tokensForSale, this.idoDecimals),
          tokensAllocated: bnHelper.fromSolDecimals(allocatedToken, this.idoDecimals),
          maxCost: bnHelper.fromSolDecimals(maxCost, this.tradeDecimals),
          delayTime: (delayTime || new BN(0)).toNumber(),
        }))
        this._loaded = true
        resolve(null)
      } catch (error) {
        reject(error)
        this._loadTask = undefined
      }
    })
    return this._loadTask
  }

  get poolInfo() {
    return {
      tokenDecimals: this.poolAccount.idoDecimals,
      tokensForSale: this._tokensForSale,
      tradeValue: this._tradeValue,
      tokensAllocated: this._tokensAllocated,
      tokensFund: this._tokensFund,
      unsoldTokenReedemed: this.poolAccount.unsoldTokensReedemed,
      hasWhitelisting: true,
      redeemConfigs: [...this._redeemConfigs],
      tierConfigs: [...this._tierConfigs],
      participants: this.poolAccount.participants,
      paused: this.poolAccount.paused,
      userFee: this._userFee,
      sellerTax: bnHelper.fromSolDecimals(this.poolAccount.taxSeller),
    }
  }

  async fetchPoolInfo() {
    const poolAccount: PoolAccountType = await this.getPoolAccount()
    this.poolAccount = poolAccount
    this._tokensAllocated = bnHelper.fromSolDecimals(poolAccount.allocatedToken, this.idoDecimals)
    this._tokensFund = bnHelper.fromSolDecimals(poolAccount.tokensFund, this.idoDecimals)
    this._tierConfigs = poolAccount.tiers.map(({ id, tokensForSale, maxCost, allocatedToken, delayTime }) => ({
      id,
      tokensForSale: bnHelper.fromSolDecimals(tokensForSale, this.idoDecimals),
      tokensAllocated: bnHelper.fromSolDecimals(allocatedToken, this.idoDecimals),
      maxCost: bnHelper.fromSolDecimals(maxCost, this.tradeDecimals),
      delayTime: (delayTime || new BN(0)).toNumber(),
    }))
  }

  _userCacheds: {
    [id: string]: {
      boughtAmounts: FixedNumber
      redeemedAmounts: FixedNumber
      isWhitelisted: boolean
      tierId: number
      keyId: number
      keyIdOwner: string
      currentUserTierId: number
      isBuyer: boolean
      paidFee: boolean
      maxUserIdoAmount: FixedNumber
      userTier: TierConfig
      userAccount?: UserAccountType
    }
  } = {}

  async fetchUserConstraints(account, force = false) {
    const poolAccount = this.poolAccount

    if (!this._userCacheds[account] || force) {
      // tierId & whitelist
      let tierId = this._userCacheds[account]?.tierId
      let isWhitelisted = this._userCacheds[account]?.isWhitelisted

      // user info
      const userAccount = (await this.getUserAccount(account)) as UserAccountType | undefined

      if (!isNumber(tierId)) {
        if (userAccount) {
          tierId = userAccount.tierId
          isWhitelisted = true
        } else {
          const whitelistAccs = await this.getWhitelistAccounts()
          const whitelists = flatten(whitelistAccs.map((m) => m.whitelist))
          const item = whitelists.find((x) => x.address.equals(new PublicKey(account)))
          tierId = item?.tier || 0
          isWhitelisted = !!item

          if (!isWhitelisted) {
            isWhitelisted = !this.poolAccount.hasWhitelist
          }

          if (poolAccount.openCommunityForAll) tierId = 0
        }
      }

      const farmContract = FarmContractSolana.getInstance(this.provider)
      await farmContract.loadIfNeed()
      const userInfo = await farmContract.getUserAccount(account)
      let stakedAmount = userInfo?.amount || new BN(0)
      if (!farmContract.poolAccount) throw 'poolAccount is undefined'
      const amountMultipler = farmContract.poolAccount.amountMultipler
      stakedAmount = stakedAmount.mul(amountMultipler)

      const { id: currentUserTierId } = [...poolAccount.tiers]
        .reverse()
        .find((x) => stakedAmount.cmp(x.stakedAmount) >= 0) || { id: -1 }

      const tierConfig = poolAccount.tiers.find((x) => x.id === tierId) as any
      let maxUserIdoAmount = FixedNumber.from('0')
      if (currentUserTierId >= tierId) {
        maxUserIdoAmount = bnHelper.fromSolDecimals(tierConfig.maxCost, this.tradeDecimals).divUnsafe(this._tradeValue)
      }

      const paidFee = !!userAccount?.paidFee
      let contributeAccount: ContributeAccountType | undefined
      if (userAccount?.paidFee) {
        const [contributeSigner]: any[] = await this.getContributeSigners(userAccount.contributeId)
        contributeAccount = await this.getContributeAccount(contributeSigner)
      }
      const boughtAmounts = bnHelper.fromSolDecimals(contributeAccount?.boughtAmounts || '0', this.idoDecimals)
      const redeemedAmounts = bnHelper.fromSolDecimals(contributeAccount?.redeemedAmounts || '0', this.idoDecimals)

      this._userCacheds[account] = {
        keyId: contributeAccount?.id.toNumber() || 0,
        keyIdOwner: contributeAccount?.authority.toString() || blockchainHandler.ETHER_ZERO_ADDRESS,
        currentUserTierId,
        boughtAmounts,
        redeemedAmounts,
        isWhitelisted,
        tierId,
        paidFee,
        isBuyer: !boughtAmounts.isZero(),
        maxUserIdoAmount,
        userTier: {
          id: tierId,
          maxCost: bnHelper.fromSolDecimals(tierConfig.maxCost, this.tradeDecimals),
          tokensForSale: bnHelper.fromSolDecimals(tierConfig.tokensForSale, this.idoDecimals),
          tokensAllocated: bnHelper.fromSolDecimals(tierConfig.allocatedToken, this.idoDecimals),
          delayTime: tierConfig.delayTime.toNumber(),
        },
        userAccount,
      }
    }
    const cached = this._userCacheds[account]
    return {
      ...cached,
      userTier: {
        ...cached.userTier,
      },
    }
  }

  async hasCreatedUserAccount(account) {
    if (!this._userCacheds[account]) {
      await this.fetchUserConstraints(account)
    }

    return !!this._userCacheds[account]?.userAccount
  }

  _userKeyInfoCacheds: {
    [account: string]: MarketKeyInfo[]
  } = {}

  async fetchUserMarketKeyInfos(account: string, force?: boolean): Promise<MarketKeyInfo[]> {
    const accountPubkey = account
    if (!this._userKeyInfoCacheds[account] || force) {
      const contributes = await this.program.account.contributeAccount.all([
        {
          memcmp: {
            offset: 8,
            bytes: this.poolId,
          },
        },
        {
          memcmp: {
            offset: 40,
            bytes: accountPubkey,
          },
        },
      ] as any)
      this._userKeyInfoCacheds[account] = contributes.map((c) => this._buildKeyInfo(c.account as ContributeAccountType))
    }

    return [...this._userKeyInfoCacheds[account]]
  }

  _buildKeyInfo(contributeAccount: ContributeAccountType): MarketKeyInfo {
    const boughtAmounts = bnHelper.fromSolDecimals(contributeAccount.boughtAmounts || '0', this.idoDecimals)
    const redeemedAmounts = bnHelper.fromSolDecimals(contributeAccount.redeemedAmounts || '0', this.idoDecimals)
    const purchases: FixedSwapContractPurchase[] = []
    let remainedPercentage = FixedNumber.from('100')
    for (const redeem of this._redeemConfigs) {
      remainedPercentage = remainedPercentage.subUnsafe(redeem.percentage)
      const percentage = redeem.percentage.divUnsafe(FixedNumber.from('100'))
      const amount = boughtAmounts.mulUnsafe(percentage)
      purchases.push({
        _id: `${redeem.id}`,
        amount,
        purchaser: contributeAccount.authority.toString(),
        ethAmount: amount.mulUnsafe(this._tradeValue),
        validAfterDate: redeem.date,
        wasFinalized: contributeAccount.redeemFinalizeds[redeem.id],
        reverted: false,
        percentage: percentage,
      })
    }
    remainedPercentage = remainedPercentage.divUnsafe(FixedNumber.from('100'))
    if (bnHelper.gt(remainedPercentage, Zero)) {
      purchases.push({
        _id: '-1',
        amount: boughtAmounts.mulUnsafe(remainedPercentage),
        purchaser: contributeAccount.authority.toString(),
        ethAmount: boughtAmounts.mulUnsafe(remainedPercentage).mulUnsafe(this._tradeValue),
      } as any)
    }
    return {
      id: contributeAccount.id.toNumber(),
      poolAddress: contributeAccount.poolAccount.toString(),
      boughtAmounts,
      redeemedAmounts,
      owner: contributeAccount.authority.toString(),
      remain: boughtAmounts.subUnsafe(redeemedAmounts),
      purchases,
    }
  }

  isFinalized(): Promise<boolean> {
    const poolAccount = this.poolAccount
    return Promise.resolve(Date.now() / 1000 > (poolAccount.endTime as BN).toNumber())
  }
  isOpen(): Promise<boolean> {
    const poolAccount = this.poolAccount
    return Promise.resolve(Date.now() / 1000 > (poolAccount.startTime as BN).toNumber())
  }

  async swap(amount: FixedNumber, account: string): Promise<any> {
    const userAccount = this._userCacheds[account].userAccount
    if (!userAccount) throw 'userAccount is undefined'

    const [contributeSigner, contributeBump] = await this.getContributeSigners(userAccount.contributeId)

    const poolAccount = this.poolAccount
    const [userAccount1] = await this.getUserId(account)
    const id = account

    // tier
    // const { tierId } = await this.fetchUserConstraints(account)
    const farmContract = FarmContractSolana.getInstance(this.provider)
    await farmContract.loadIfNeed()
    const userInfo = await farmContract.getUserAccount(account)
    const isFarmer = !!userInfo
    let remainingAccounts: any[] = []
    if (isFarmer) {
      remainingAccounts = [
        { pubkey: await farmContract.getPoolSigner(), isWritable: false, isSigner: false },
        { pubkey: (await farmContract.getUserSigner(account)).userAccount, isWritable: false, isSigner: false },
      ]
    }

    try {
      const buyTx = this.program.transaction.buy(
        userAccount.contributeId,
        contributeBump,
        bnHelper.toSolDecimal(amount, this.idoDecimals),
        isFarmer,
        {
          accounts: blockchainHandler.fixAnchorAccounts({
            userAccount: userAccount1.toString(),
            contribute: contributeSigner.toString(),
            authority: id,
            state: (await this.getStateSigner()).toString(),
            poolSigner: (await this.getPoolSigner()).toString(),
            poolAccount: this.poolId,
            tradeUserVault: (await this.tradeTokenProgram.getAssociatedTokenAddress(new PublicKey(id))).toString(),
            tradePoolVault: poolAccount.tradePoolVault.toString(),
            tradeFeeVault: poolAccount.tradeFeeVault.toString(),
            tokenProgram: TOKEN_PROGRAM_ID.toString(),
            rent: anchor.web3.SYSVAR_RENT_PUBKEY.toString(),
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
            systemProgram: SystemProgram.programId.toString(),
          }),
          remainingAccounts,
        }
      )
      await this.provider.send(buyTx, [], {})
    } catch (error) {
      console.error(error)
      blockchainHandler.throwSolanaAnchorError(error, idov1)
    }
  }
  async redeemTokens(keyId: number, purchase_id: any, account: string): Promise<any> {
    const [userId] = await this.getUserId(account)
    const [contrbuteSigner] = await this.getContributeSigners(keyId)
    const poolAccount = this.poolAccount
    const accounts = blockchainHandler.fixAnchorAccounts({
      contribute: contrbuteSigner,
      userAccount: userId,
      authority: account,
      poolSigner: await this.getPoolSigner(),
      idoMint: poolAccount.idoMint.toString(),
      poolAccount: this.poolId,
      idoCreatorVault: (await this.idoTokenProgram.getAssociatedTokenAddress(poolAccount.creatorAuthority)).toString(),
      idoUserVault: (await this.idoTokenProgram.getAssociatedTokenAddress(new PublicKey(account))).toString(),
      idoPoolVault: poolAccount.idoPoolVault.toString(),
      tokenProgram: TOKEN_PROGRAM_ID.toString(),
      rent: anchor.web3.SYSVAR_RENT_PUBKEY.toString(),
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
      systemProgram: SystemProgram.programId.toString(),
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID.toString(),
    })
    await this.program.rpc.redeemTokens(new BN(keyId), new BN(purchase_id), {
      accounts,
    })
  }

  async cost(amount: FixedNumber): Promise<FixedNumber> {
    return Promise.resolve(amount.mulUnsafe(this._tradeValue))
  }
  async isPaused(): Promise<boolean> {
    const poolAccount = await this.getPoolAccount()
    return poolAccount.paused
  }
  async fund(amount: string, account: any): Promise<any> {
    const poolAccount = this.poolAccount
    account = new PublicKey(account)
    await this.program.rpc.fund(bnHelper.toSolDecimal(amount, this.idoDecimals), {
      accounts: blockchainHandler.fixAnchorAccounts({
        poolAccount: this.poolId,
        poolSigner: await this.getPoolSigner(),
        authority: account,
        idoUserVault: (await this.idoTokenProgram.getAssociatedTokenAddress(account)).toString(),
        idoPoolVault: poolAccount.idoPoolVault.toString(),
        tokenProgram: TOKEN_PROGRAM_ID.toString(),
        rent: anchor.web3.SYSVAR_RENT_PUBKEY.toString(),
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
        systemProgram: SystemProgram.programId.toString(),
      }),
    })
  }
  unpauseContract(account: any): Promise<any> {
    return this.program.rpc.changePoolSettingBySeller(false, {
      accounts: blockchainHandler.fixAnchorAccounts({
        poolAccount: this.poolId,
        seller: account,
      }),
    })
  }
  pauseContract(account: any): Promise<any> {
    return this.program.rpc.changePoolSettingBySeller(true, {
      accounts: blockchainHandler.fixAnchorAccounts({
        poolAccount: this.poolId,
        seller: account,
      }),
    })
  }
  async withdrawFunds(account: any): Promise<any> {
    const poolAccount = this.poolAccount
    await this.program.rpc.withdrawFunds({
      accounts: blockchainHandler.fixAnchorAccounts({
        poolAccount: this.poolId,
        poolSigner: await this.getPoolSigner(),
        authority: account,
        tradeSellerVault: (await this.tradeTokenProgram.getAssociatedTokenAddress(poolAccount.seller)).toString(),
        tradeCreatorVault: (
          await this.tradeTokenProgram.getAssociatedTokenAddress(poolAccount.creatorAuthority)
        ).toString(),
        tradePoolVault: poolAccount.tradePoolVault.toString(),
        tradeFeeVault: poolAccount.tradeFeeVault.toString(),
        tokenProgram: TOKEN_PROGRAM_ID.toString(),
        rent: anchor.web3.SYSVAR_RENT_PUBKEY.toString(),
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
        systemProgram: SystemProgram.programId.toString(),
      }),
    })
  }
  async withdrawUnsoldTokens(account: any): Promise<any> {
    const poolAccount = this.poolAccount
    await this.program.rpc.withdrawUnsoldTokens({
      accounts: blockchainHandler.fixAnchorAccounts({
        poolAccount: this.poolId,
        poolSigner: await this.getPoolSigner(),
        authority: account,
        idoUserVault: (await this.idoTokenProgram.getAssociatedTokenAddress(account)).toString(),
        idoPoolVault: poolAccount.idoPoolVault.toString(),
        tokenProgram: TOKEN_PROGRAM_ID.toString(),
        rent: anchor.web3.SYSVAR_RENT_PUBKEY.toString(),
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
        systemProgram: SystemProgram.programId.toString(),
      }),
    })
  }

  async createUserAccount(account: string) {
    try {
      const tx = await this.getCreateUserAccountTransaction(account)
      await this.provider.send(tx, [], {})
    } catch (error) {
      blockchainHandler.throwSolanaAnchorError(error, idov1)
    }
  }

  async getCreateUserAccountTransaction(account: string) {
    const poolAccount = this.poolAccount
    const accountId = new PublicKey(account)
    const [userAccount, bump] = await this.getUserId(account)
    let remainingAccounts: any = undefined
    if (poolAccount.hasWhitelist) {
      const whitelistAcc = this._whitelistAccounts
        .find(({ whitelist }) => whitelist.find((x) => x.address.equals(accountId)))
        ?.publicKey.toString()
      if (whitelistAcc) {
        remainingAccounts = [
          {
            pubkey: whitelistAcc,
            isWritable: false,
            isSigner: false,
          },
        ]
      }
    }
    return this.program.transaction.createUser(bump, {
      accounts: blockchainHandler.fixAnchorAccounts({
        poolAccount: this.poolId,
        userAccount: userAccount.toString(),
        state: await this.getStateSigner(),
        authority: accountId.toString(),
        systemProgram: SystemProgram.programId.toString(),
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY.toString(),
      }),
      remainingAccounts,
    })
  }

  async getPoolAccount() {
    return (await this.program.account.poolAccount.fetch(this.poolId)) as PoolAccountType
  }

  async getContributeSigners(contributeId) {
    const [contributeSigner, contributeBump] = await anchor.web3.PublicKey.findProgramAddress(
      [utf8.encode('contribute'), utf8.encode(contributeId.toString())],
      this.program.programId
    )
    return [contributeSigner.toString(), contributeBump]
  }

  async getContributeAccount(pubkey: string) {
    try {
      return (await this.program.account.contributeAccount.fetch(pubkey)) as ContributeAccountType
    } catch (error) {
      return undefined
    }
  }

  async getUserId(owner: string) {
    const [_userAccount, bump] = await PublicKey.findProgramAddress(
      [new PublicKey(this.poolId).toBuffer(), new PublicKey(owner).toBuffer()],
      new PublicKey(IDO_ID)
    )
    return [_userAccount.toString(), bump]
  }

  async getUserAccount(owner: string) {
    const [userId] = await this.getUserId(owner)
    try {
      return await this.program.account.userAccount.fetch(userId.toString())
    } catch (error) {
      return undefined
    }
  }

  async getPoolSigner() {
    const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress(
      [new PublicKey(this.poolId).toBuffer()],
      this.program.programId
    )
    return _poolSigner.toString()
  }

  async getStateSigner() {
    const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress([utf8.encode('state')], this.program.programId)
    return _poolSigner.toString()
  }

  _whitelistAccounts: PoolWhitelistAccountType[] = []
  _loadWhitelistTask?: Promise<any>
  private async getWhitelistAccounts() {
    if (this._loadWhitelistTask) {
      await this._loadWhitelistTask
      return this._whitelistAccounts
    }
    this._loadWhitelistTask = this.program.account.poolWhitelistAccount
      .all(new PublicKey(this.poolId).toBuffer())
      .then((results) => {
        const whitelistAccounts = results.map(
          (x) =>
            ({
              ...x.account,
              publicKey: x.publicKey,
            } as PoolWhitelistAccountType)
        )
        this._whitelistAccounts = whitelistAccounts
        return whitelistAccounts
      })
    await this._loadWhitelistTask
    return this._whitelistAccounts
  }
}

interface PoolAccountType {
  allocatedToken: BN
  bump: number
  creatorAuthority: PublicKey
  endTime: BN
  hasWhitelist: boolean
  idoDecimals: number
  idoMint: PublicKey
  idoPoolVault: PublicKey
  keyMetas: PublicKey[]
  numMetas: BN[]
  participants: number
  paused: boolean
  allowBuyLowerTier: boolean
  tiers: { id: number; tokensForSale: BN; allocatedToken: BN; maxCost: BN; stakedAmount: BN; delayTime: BN }[]
  redeemConfigs: { id: number; date: BN; percentage: BN }[]
  seller: PublicKey
  startTime: BN
  userFee: BN
  taxSeller: number
  tokensForSale: BN
  tokensFund: BN
  tradeDecimals: number
  tradeMint: PublicKey
  tradePoolVault: PublicKey
  tradeFeeVault: PublicKey
  tradeValue: BN
  unsoldTokensReedemed: boolean
  withdrawFunds: boolean
  openCommunityForAll: boolean
}

interface UserAccountType {
  poolAccount: PublicKey
  authority: PublicKey
  // boughtAmounts: BN
  contributeId: BN
  // redeemedAmounts: BN
  bump: number
  // redeemFinalizeds: boolean[]
  tierId: number
  paidFee: boolean
}

interface ContributeAccountType {
  poolAccount: PublicKey
  authority: PublicKey
  id: BN
  boughtAmounts: BN
  redeemedAmounts: BN
  bump: number
  redeemFinalizeds: boolean[]
}

interface PoolWhitelistAccountType {
  publicKey: PublicKey
  poolAccount: PublicKey
  len: number
  whitelist: { address: PublicKey; tier: number }[]
}
