import Web3 from 'web3'
import { blockchainHandler } from '.'
import marketNftAbi from './abis/market-nft.abi.json'

const cacheds: {
  [address: string]: MarketNftContract
} = {}

export class MarketNftContract {
  contract: any

  private constructor(public address: string, public web3: Web3) {
    this.contract = new web3.eth.Contract(marketNftAbi as any, address)
  }

  static getInstance(address: string, web3: Web3) {
    address = Web3.utils.toChecksumAddress(address)
    if (!cacheds[address]) cacheds[address] = new MarketNftContract(address, web3)
    return cacheds[address]
  }

  injectProvider(web3: Web3) {
    this.web3 = web3
    this.contract = new web3.eth.Contract(marketNftAbi as any, this.address)
  }

  getMyKeyIdsByMarket(user: string, market: string): Promise<string[]> {
    return this.contract.methods.getMyKeyIdsByMarket(user, market).call()
  }

  async getKeyInfo(keyId: any): Promise<{ market: string; owner: string }> {
    const methods = this.contract.methods
    const [{ market }, owner]: any[] = await blockchainHandler.etherBatchRequest(this.web3, [
      methods.keyInfos(keyId),
      methods.ownerOf(keyId),
    ])
    return { market, owner }
  }

  transfer(user: string, to: string, keyId: any) {
    return sendRequest(this.contract.methods.safeTransferFrom(user, to, keyId), user)
  }

  isApprovedAll(user: string, market: string): Promise<boolean> {
    return this.contract.methods.isApprovedForAll(user, market).call()
  }

  aprroveAll(user: string, market: string) {
    return sendRequest(this.contract.methods.setApprovalForAll(market, true), user)
  }
}
function sendRequest(fx, from): Promise<any> {
  return new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve(''))
      .on('error', (error) => reject(error))
  })
}
