import { localData } from '@/stores/local-data'
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import settingsSvg from '../assets/icons/settings.svg'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'fa',
  icons: {
    iconfont: 'fa',
    values: {
      // settings: settingsSvg,
    },
  },
  theme: {
    dark: !localData.lightmode,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#F47A60',
          lighten1: '#FCE7DE',
          lighten3: '#fdf0f5',
        },
        success: {
          base: '#5FCD5B',
          lighten1: '#dff5de',
        },
        secondary: '#424242',
        accent: '#0EB2E5',
        error: '#F44336',
        info: '#2196F3',
        warning: '#FFC107',
        grey1: '#72687A',
        subtitle: '#969696',
        red1: '#FF0000',
      },
      dark: {
        primary: {
          base: '#FFC107',
          lighten1: '#201F22',
          lighten2: '#2C2230',
          lighten3: '#211021',
          lighten4: '#2D2D2D',
        },
        success: {
          base: '#5FCD5B',
          lighten1: '#dff5de',
        },
        lightGray: '#969696',
        grey1: '#B9B9B1',
        subtitle: '#969696',
        red1: '#F6616A',
        background: {
          base: '#181718',
          lighten1: '#201F22',
        },
      },
    },
  },
})
