import { numberHelper } from '@/helpers/number.hepler'
import { FixedNumber } from '@ethersproject/bignumber'
import { findLast } from 'lodash'
export const Zero = FixedNumber.from(0)
export const FIXEDNUMBER_100 = FixedNumber.from('100')
export const NO_TIER = 'NO TIER'
export const UNLIMITED_ALLOCATION = FixedNumber.from('1000000')
export const enum AccessType {
  Whitelist = 'whitelist',
  Wag = 'WAG',
}

const getText = (x) => `Max allocation: ${numberHelper.usdFormat(x, 0)}`
const lst = [
  {
    image: require('@/assets/images/tier-1.svg'),
    amount: 100,
    text: getText(200),
    currectTier: false,
    name: 'MINI BEE',
    tierIndex: 1,
  },
  {
    image: require('@/assets/images/tier-2.svg'),
    amount: 1000,
    text: getText(2000),
    currectTier: false,
    name: 'WORKER BEE',
    tierIndex: 2,
  },
  {
    image: require('@/assets/images/tier-3.svg'),
    amount: 5000,
    text: getText(10000),
    currectTier: false,
    name: 'DRONE BEE',
    tierIndex: 3,
  },
  {
    image: require('@/assets/images/tier-4.svg'),
    amount: 10000,
    text: getText(20000),
    currectTier: false,
    name: 'QUEEN BEE',
    tierIndex: 4,
  },
]
function getTierList() {
  return lst.map((x) => ({
    ...x,
  }))
}
function findTierByAmount(stakedAmount: number) {
  return findLast(lst, (x) => stakedAmount >= x.amount)
}
export const tierHelper = { getTierList, findTierByAmount }
const devnetworks = [
  { title: 'BSC', chain: 'bsc', value: 56 },
  { title: 'BSC-dev', chain: 'bsc', value: 97 },
  // { title: 'Ethereum', value: 'eth' },
  { title: 'Solana', chain: 'sol', value: 101 },
  { title: 'Solana-dev', chain: 'sol', value: 103 },
]
const mainnetworks = [
  { title: 'BSC', chain: 'bsc', value: 56 },
  // { title: 'Ethereum', value: 'eth' },
  { title: 'Solana', chain: 'sol', value: 101 },
]

export const networks = process.env.VUE_APP_FARM_NETWORK === 'production' ? mainnetworks : devnetworks
