import { bnHelper } from '@/helpers/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import { Provider } from '@project-serum/anchor'
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { Keypair, PublicKey, Transaction } from '@solana/web3.js'

export class SlpTokenProgram {
  mint: PublicKey
  token: Token
  _decimals: number | null = null

  constructor(mint: PublicKey, public provider: Provider) {
    this.mint = new PublicKey(mint)
    this.token = new Token(provider.connection, this.mint, TOKEN_PROGRAM_ID, new Keypair())
  }

  injectProvider(provider: Provider) {
    this.provider = provider
    this.token = new Token(provider.connection, this.mint, TOKEN_PROGRAM_ID, new Keypair())
  }

  async decimals() {
    const result = this._decimals || (await this.token.getMintInfo()).decimals
    this._decimals = result
    return result
  }

  async getTokenAmount(account) {
    try {
      const ata = await this.getAssociatedTokenAddress(new PublicKey(account.toString()))
      const info = await this.token.getAccountInfo(ata)
      return bnHelper.fromSolDecimals(info.amount, await this.decimals())
    } catch {
      return FixedNumber.from('0')
    }
  }

  async createAssociatedTokenAddress(owner) {
    owner = new PublicKey(owner.toString())
    const tx = new Transaction()
    tx.add(await this.getCreateAssociatedTokenAddressInstruction(owner))
    await this.provider.send(tx, [], {})
  }

  async closeAccount(owner) {
    owner = new PublicKey(owner.toString())
    const ata = await this.getAssociatedTokenAddress(owner)
    const tx = new Transaction()
    tx.add(Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, ata, owner, owner, []))
    await this.provider.send(tx, [], {})
  }

  async getCreateAssociatedTokenAddressInstruction(owner) {
    owner = new PublicKey(owner.toString())
    const ata = await this.getAssociatedTokenAddress(owner)
    return Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      this.mint,
      ata,
      owner,
      owner
    )
  }

  getAssociatedTokenAddress(owner: PublicKey) {
    return Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      this.mint,
      new PublicKey(owner),
      true
    )
  }

  async hasAssociatedTokenAddress(owner) {
    try {
      const ata = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        this.mint,
        new PublicKey(owner.toString()),
        true
      )
      await this.token.getAccountInfo(ata)
      return true
    } catch (error) {
      return false
    }
  }
}
